export const validateEmail = (email) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/.test(email);

export const requiredField = (value) => {
  const stringTrimed = value.trim();
  return stringTrimed !== "";
};

export const validateMinLength = (value, lengthRequired) =>
  value.length >= lengthRequired;

export const validateUppercase = (value) => /[A-Z]+/.test(value);

export const matchStringValidate = (str1, str2) => str1 === str2;
