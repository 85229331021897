import React, { useState, useEffect } from "react";
import { CreateBranchLayout } from "./styledComponents";
import {
  DashboardSectionTitle,
  DashboardCard,
  InputLayout,
  FlexContainerHelper,
} from "../../../../components/dashboard-layout/styledComponents";
import { Row, Col } from "antd";
import Button from "../../../../components/dashboard-layout/button/Button";
import GoogleMapReact from "google-map-react";
import { MdLocationOn } from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { createPartnerBranch } from "../../../../services/partners.service";

const REACT_APP_GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const greatPlaceStyle = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
};
export default function CreateBranch() {
  const { control, handleSubmit, errors } = useForm();
  const [placeSelected, setPlaceSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onSubmit = async (data) => {
    if (placeSelected) {
      setLoading(true);
      try {
        const branchData = {
          name: data.name,
          latitude: currentMarkerPosition.lat,
          longitude: currentMarkerPosition.lng,
          phoneNumber: data.phone,
          email: data.email,
          address: data.address,
          nit: data.nit,
        };
        await createPartnerBranch(branchData);
        toast.success("Se ha creado la sucursal satisfactoriamente");
        setLoading(false);
        setPlaceSelected(false);
        history.push("/branches");
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      toast.error("Debes seleccionar una ubicación");
    }
  };
  const [currentMarkerPosition, setMarkerPosition] = useState({
    lat: 4.657131,
    lng: -74.058811,
  });
  const handleCurrentPosition = (lat, lng) => {
    setMarkerPosition({
      lat: lat,
      lng: lng,
    });
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) =>
      handleCurrentPosition(position.coords.latitude, position.coords.longitude)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarkerPosition.toString()]);

  return (
    <CreateBranchLayout>
      <DashboardSectionTitle>Crear sucursal</DashboardSectionTitle>
      <DashboardCard minheight="570px" className="create-branch-container">
        <Row>
          <Col span={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexContainerHelper
                direction="column"
                align="stretch"
                justify="space-between"
                className="create-branch-form-container"
              >
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Neque unde quibusdam culpa saepe ea sequi assumenda
                  </p>
                </div>
                <div>
                  <label htmlFor="name">Nombre</label>
                  <Controller
                    as={InputLayout}
                    name="name"
                    control={control}
                    defaultValue=""
                    placeholder="Ingrese nombre"
                    type="text"
                    rules={{
                      required: "Este campo es requerido.",
                      maxLength: {
                        value: 45,
                        message: "Máximo 45 caracteres permitidos",
                      },
                    }}
                  />
                  {errors?.name && (
                    <span className="validation-error">
                      {errors?.name?.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="name">NIT</label>
                  <Controller
                    as={InputLayout}
                    name="nit"
                    control={control}
                    defaultValue=""
                    placeholder="Ingrese NIT"
                    type="text"
                    rules={{
                      required: "Este campo es requerido.",
                      maxLength: {
                        value: 15,
                        message: "NIT no debe tener más 15 carácteres",
                      },
                    }}
                  />
                  {errors?.nit && (
                    <span className="validation-error">
                      {errors?.nit?.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="email">Correo Electrónico</label>
                  <Controller
                    as={InputLayout}
                    name="email"
                    control={control}
                    defaultValue=""
                    type="email"
                    placeholder="Ingrese correo electrónico"
                    rules={{
                      required: "Este campo es requerido.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Dirección de correo electrónico inválido",
                      },
                      maxLength: {
                        value: 255,
                        message: "Máximo 255 caracteres permitidos",
                      },
                    }}
                  />
                  {errors?.email && (
                    <span className="validation-error">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="phone">Teléfono</label>
                  <Controller
                    as={InputLayout}
                    name="phone"
                    control={control}
                    defaultValue=""
                    type="tel"
                    placeholder="Ingresar teléfono"
                    rules={{
                      required: "Este campo es requerido.",
                      maxLength: {
                        value: 10,
                        message: "No puede exceder más de 10 caracteres",
                      },
                    }}
                  />
                  {errors?.phone && (
                    <span className="validation-error">
                      {errors?.phone?.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="address">Dirección</label>
                  <Controller
                    as={InputLayout}
                    name="address"
                    control={control}
                    defaultValue=""
                    type="text"
                    placeholder="Ingresar dirección"
                    rules={{
                      required: "Este campo es requerido.",
                      maxLength: {
                        value: 255,
                        message: "Máximo 255 caracteres permitidos",
                      },
                    }}
                  />
                  {errors?.address && (
                    <span className="validation-error">
                      {errors?.address?.message}
                    </span>
                  )}
                </div>
                <div>
                  <FlexContainerHelper>
                    <Button className="cancel-btn" type="alternative">
                      <Link to="/branches">Cancelar</Link>
                    </Button>
                    <Button type="submit">Crear sucursal</Button>
                  </FlexContainerHelper>
                </div>
              </FlexContainerHelper>
            </form>
          </Col>
          <Col span={18}>
            <FlexContainerHelper justify="flex-end">
              <div style={{ height: "520px", width: "95%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: REACT_APP_GOOGLE_MAP_KEY,
                  }}
                  center={currentMarkerPosition}
                  defaultZoom={14}
                  yesIWantToUseGoogleMapApiInternals
                  onClick={(coord) => {
                    if (!placeSelected) {
                      setPlaceSelected(true);
                    }
                    handleCurrentPosition(coord.lat, coord.lng);
                  }}
                >
                  <MdLocationOn
                    size={38}
                    color={placeSelected ? "#db2525" : "#9e9b9b"}
                    lat={currentMarkerPosition.lat}
                    lng={currentMarkerPosition.lng}
                    style={greatPlaceStyle}
                  />
                </GoogleMapReact>
              </div>
            </FlexContainerHelper>
          </Col>
        </Row>
        {loading && (
          <div className="loader-container">
            <Spin size={"large"}></Spin>
          </div>
        )}
      </DashboardCard>
    </CreateBranchLayout>
  );
}
