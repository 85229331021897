import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "antd";
import styled from "styled-components";

import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsTransactions } from "./tableConfig";
import { getWalletMovements } from "../../../services/wallets.service";
import usePagination from "../../../hooks/usePagination";

const { RangePicker } = DatePicker;

const RangePickerStyled = styled(RangePicker)`
  margin-left: 10px;
`;

export default function BranchTransactions() {
  const { id } = useParams();
  const [branchTransactions, setBranchTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchBarActiveVal, setSearchBarActiveVal] = useState("");
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const {
    page,
    pagination,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
  } = usePagination();

  const onGetBranchTransactions = async (pag) => {
    setLoading(true);
    try {
      const { data } = await getWalletMovements({
        page,
        itemsPerPage,
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
        partnerBranchId: parseInt(id),
      });
      const formatedRows = data.result?.data?.map((row) => {
        const formatData = {
          name: `${row?.origin?.firstName} ${row?.origin?.lastName}`,
          product: row?.productName || "-",
          date: moment(row?.createdAt).format("MMM DD, YYYY  hh:mm a"),
          mount: `$ ${new Intl.NumberFormat("de-DE").format(row?.value)}`,
        };
        return formatData;
      });
      setBranchTransactions(formatedRows);
      setPagination(data.result.pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onChangeSearchBar = (e) => {
    setSearchBarActiveVal(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => onGetBranchTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarActiveVal, dateRange, page, itemsPerPage]);

  return (
    <>
      <DashboardTable
        columns={columnsTransactions}
        tableTitle="Transacciones"
        data={branchTransactions}
        pagination={pagination}
        page={page}
        rowKey="key"
        loading={loading}
        searchBarPlaceholder="Buscar sucursales"
        onChangeSearchBar={onChangeSearchBar}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        customButton={
          <RangePickerStyled
            defaultValue={[moment().subtract(30, "days"), moment()]}
            ranges={{
              Hoy: [moment(), moment()],
              "Mes en curso": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              Semana: [moment().startOf("week"), moment().endOf("week")],
              "Últimos 30 días": [moment().subtract(30, "days"), moment()],
            }}
            onChange={(dates, datesString) =>
              setDateRange([
                moment(dates[0]).startOf("day").format(),
                moment(dates[1]).endOf("day").format(),
              ])
            }
          />
        }
      />
    </>
  );
}
