import styled from "styled-components";
import backgroundImg from "../../assets/img/csm_AdobeStock_87208847_4457727942.png";
import { screen } from "../../utils/constants";
import { Link } from "react-router-dom";

export const MainSection = styled.section`
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 0;
  & p,
  span,
  div,
  a {
    font-family: "Aeonik";
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
  }

  & a {
    color: #051a55;
    text-decoration: none;
    outline: 0;
  }

  & label {
    font-family: "Aeonik";
    font-weight: bolder;
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Aeonik";
    font-weight: bold;
  }

  & h2 {
    font-size: 25px;
  }
`;

export const BackgroundLayer = styled.div`
  background-color: #051a55;
  opacity: 0.9;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ScreenTitle = styled.h5`
  color: #76889a;
  letter-spacing: 0.6px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const MainTitle = styled.h2`
  text-align: center;
  font: Bold 25px/30px Aeonik;
  letter-spacing: 0px;
  color: #051a55;
  margin-bottom: 6px;
`;

export const AuthCard = styled.div`
  width: 469px;
  background-color: white;
  border-radius: 8px;
  color: #585858;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 55px;
  z-index: 3;
  position: relative;
  box-shadow: #00000029 0px 3px 6px;
  @media (max-width: ${screen.MD}) {
    width: 400px;
    padding: 30px;
  }
  @media (max-width: ${screen.XS}) {
    width: 300px;
    padding: 10px;
  }
`;

export const AuthText = styled.p`
  color: #585858;
  margin-top: 6px;
  text-align: ${(props) => props.alignText || "left"};
`;

export const AuthInputStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  & label {
    color: #0a181d;
    margin-top: 15px;
  }
  & input,
  input:focus {
    border: ${(props) =>
      props.error ? "1px solid #E4032B" : "1px solid #76889a81"};
    border-radius: 4px;
    outline: 0;
    padding: 10px;
    margin-top: 5px;
  }
`;

export const AuthInputIconStyle = styled.span`
  position: absolute;
  top: 60%;
  left: 90%;
  color: #76889a;
  font-size: 18px !important;
  cursor: pointer;
`;

export const FormAuthStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const AuthSubmitBtn = styled.button`
  display: block;
  background: #051a55 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bolder;
  :focus {
    outline: 0;
  }
  ${(props) =>
    props.formInvalid
      ? "background-color: gray; cursor: not-allowed"
      : "background: #051A55 0% 0% no-repeat padding-box;"}
`;

export const ErrorMessage = styled.span`
  color: #e4032b;
  display: ${(props) => (props.error ? "block" : "none")};
`;

export const PassWordListRequirement = styled.ul`
  padding-left: 5px;
  list-style: none;
  margin-top: 2px;
  color: ${(props) => props.error};
  & li {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  & li.minlength {
    color: ${(props) => (props.validateMinLength ? "#00bf6e" : "#e4032b")};
  }
  & li.uppercase {
    color: ${(props) => (props.validateUpperCase ? "#00bf6e" : "#e4032b")};
  }
  & li.confirmation {
    color: ${(props) => (props.validMatch ? "#00bf6e" : "#e4032b")};
  }
  & svg {
    border: 1px solid;
    padding: 1px;
    border-radius: 50%;
    margin-right: 5px;
  }

  & li.minlength svg {
    border-color: ${(props) =>
      props.validateMinLength ? "#00bf6e" : "#e4032b"};
  }
  & li.uppercase svg {
    border-color: ${(props) =>
      props.validateUpperCase ? "#00bf6e" : "#e4032b"};
  }
  & li.confirmation svg {
    border-color: ${(props) => (props.validMatch ? "#00bf6e" : "#e4032b")};
  }
`;

export const CardLink = styled(Link)`
  font-size: 14px;
  color: #1645b8;
`;
