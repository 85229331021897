import styled from "styled-components";

export const FooterNav = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: auto;
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  & img {
    width: 42px;
    height: auto;
    margin-left: 1em;
  }
`;
