import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Row, Col } from "antd";

import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { getWalletMovements } from "../../../services/wallets.service";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import usePagination from "../../../hooks/usePagination";

const { RangePicker } = DatePicker;

const columns3 = [
  {
    title: "Transportador",
    dataIndex: "driver",
    key: "driver",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Sucursal",
    dataIndex: "branch",
    key: "branch",
    sortDirections: ["descend"],
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "amount",
    key: "amount",
    sortDirections: ["descend"],
  },
];

const DashboardAlly = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);

  const {
    page,
    pagination,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
  } = usePagination();

  useEffect(() => {
    onGetBranchesTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, page, itemsPerPage]);

  const onGetBranchesTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getWalletMovements({
        page,
        itemsPerPage,
        ...(dateRange &&
          dateRange.length && {
            dateInitSearch: dateRange[0],
            dateEndSearch: dateRange[1],
          }),
      });
      const formattedRows = data.result?.data?.map((row) => ({
        driver: `${row?.origin?.firstName} ${row?.origin?.lastName}`,
        branch: row?.destination?.name,
        product: row?.productName,
        date: moment(row?.createdAt).format("MMM DD, YYYY  hh:mm a"),
        amount: `$ ${new Intl.NumberFormat("de-DE").format(row?.value)}`,
      }));
      setTransactions(formattedRows);
      setPagination(data.result?.pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, itemsPerPage, page]);

  return (
    <HomeAllyLayout>
      <Row align="middle">
        <Col span={12}>
          <DashboardSectionTitle>Dashboard</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>
      <DashboardTable
        tableTitle="Últimas transacciones"
        className="larga-table-ally"
        columns={columns3}
        data={transactions}
        loading={loading}
        pagination={pagination}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        page={page}
        searchBarPlaceholder="Buscar transacción"
      />
    </HomeAllyLayout>
  );
};

const HomeAllyLayout = styled.div`
  & .large-table-ally {
    height: auto;
  }
`;

export default DashboardAlly;
