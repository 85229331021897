import { axiosInstance } from "../utils/axios";

export const fetchOrganization = async (organizationId) => {
  return axiosInstance.get(`/organizations/${organizationId}`);
};

export const fetchOrganizations = async (page, itemsPerPage) => {
  return axiosInstance.get("/organizations", {
    params: { page, itemsPerPage },
  });
};

export const fetchOrganizationUsers = async (page, itemsPerPage, invited) => {
  return axiosInstance.get("/organization/users", {
    params: { page, itemsPerPage, invited },
  });
};

export const updateMaxQuotaOrganization = async (id, data) => {
  return axiosInstance.put(`/organizations/${id}/maxquota`, data);
};
