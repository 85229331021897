import React from "react";
import { LogoStyle } from "./styledComponents";
export default function Logo({ imageSource, altText }) {
  return (
    <LogoStyle>
      <img
        src={imageSource}
        alt={altText}
        style={{ height: "70px", width: "220px", objectFit: "contain" }}
      />
    </LogoStyle>
  );
}
