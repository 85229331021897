import React, { useContext } from "react";
import { Divider, Dropdown, Avatar, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ToolbarLayout, DropdownLayout } from "./styleComponents";
import { Context } from "../../../Context";

export default function ToolbarHeader({ history }) {
  const { removeAuth, currentUser } = useContext(Context);

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            removeAuth();
            history.push("/login");
          }}
        >
          Cerrar sesión
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <ToolbarLayout>
      {/* <MdNotifications size={20} style={{ flexGrow: 1 }} />
      <MdSettings size={20} style={{ flexGrow: 1 }} /> */}
      <Divider
        type="vertical"
        style={{ height: "2.2em", borderWidth: "1.2px", flexGrow: 1 }}
      />
      <Dropdown overlay={menu} placement="bottomLeft" style={{ flexGrow: 2 }}>
        <DropdownLayout>
          <Avatar size="large" style={{ flexGrow: 1, background: "#051A55" }}>
            <span style={{ fontSize: "16px", color: " #fff" }}>
              {currentUser?.firstName?.[0] || "U"}
              {currentUser?.lastName?.[0]}
            </span>
          </Avatar>
          <div style={{ flexGrow: 2, margin: "0 8px" }}>
            <h4 style={{ margin: "0", color: "#0A181D" }}>
              {currentUser?.firstName || "Sin nombre"}{" "}
              {currentUser?.lastName || ""}
            </h4>
            <span>Administrador</span>
          </div>
          <DownOutlined color={"#0A181D"} style={{ flexGrow: 1 }} />
        </DropdownLayout>
      </Dropdown>
    </ToolbarLayout>
  );
}
