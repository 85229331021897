import React from "react";
import { FooterStyle } from "./styleComponents";
// import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <FooterStyle>
      <p>{`© HOY+ ${new Date().getFullYear()}. Todos los derechos reservados`}</p>
      <p>Version 1.4.0</p>
    </FooterStyle>
  );
}
