import styled from "styled-components";

export const CreateBranchLayout = styled.div`
  & .create-branch-container .ant-row {
    width: 100%;
    position relative
  }

  & form {
    height: 100%;
  }

  & .create-branch-form-container {
    height: 100%;
  }
  & input {
    height: 38px;
    border-radius: 3px;
  }

  & .cancel-btn {
    margin-left: 0;

    & a {
      color: inherit;
      text-decoration: none;
      outline: 0;
    }
  }

  & .validation-error {
    color: red;
  }

  & .loader-container {
      width: 100%;
      height: 520px;
      position absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,0.5);
  }
`;
