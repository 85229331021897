import { axiosInstance } from "../utils/axios";

export const fetchPaymentIntents = async (params) => {
  return axiosInstance
    .get("/payments", {
      params: {
        ...params,
      },
    })
    .then((res) => res.data);
};

export const createPaymentIntent = async (data) => {
  return axiosInstance.post("/payments/intent", data);
};

export const cancelAdvancePayment = async (id, data) => {
  return axiosInstance.post(`/payments/advance/${id}/cancel`, data);
};

export const cancelEfectyPayment = async (id, data) => {
  return axiosInstance.post(`/payments/efecty/${id}/cancel`, data);
};

export const createPaymentIntentToDriver = async (data) => {
  return axiosInstance.post("/payments/intent/driver", data);
};

export const createAdvancePayment = async (data) => {
  return axiosInstance.post("/payments/advance", data);
};

export const createPaymentToExternalEntity = async (data) => {
  return axiosInstance.post("/payments/external/entity", data);
};

export const createPaymentWithValueServiceOrder = async (data) => {
  return axiosInstance.post("/payments/service-order", data);
};

export const fetchPaymentIntentAdvanceDetails = async (id) => {
  return axiosInstance.get(`/payments/${id}/advance`);
};

export const fetchPaymentIntentDetails = async (paymentIntentId) => {
  return axiosInstance.get(`/payments/${paymentIntentId}/detail`);
};

export const updatePaymentIntents = async (id, data) => {
  return axiosInstance.put(`/payments/${id}`, data);
};

export const refreshEfectyPayment = async (id, data) => {
  return axiosInstance.patch(`/payments/${id}/efecty`, data);
};

// export const fetchPayment = async (page, itemsPerPage, type) => {
//   return axiosInstance.get("/payments", {
//     params: { page, itemsPerPage, type },
//   });
// };
