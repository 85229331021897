import styled from "styled-components";
const primary = `
    background: #051A55 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0000002F;
    color: white;
    border: none;
`;

const secondary = `
    background: #E8EEFC;
    color: #051A55; 
    border: none;
`;

const alternative = `
    background: none;
    color: #051A55;
    border: 1px solid #051A55;
`;

const successButton = `
    background: #00BE6D;
    box-shadow: 0px 3px 9px #0000002F;
    color: white;
    border: none;
`;

const successSecondary = `
    background: transparent;
    color: #00BE6D;
    border: solid 1px #00BE6D;
`;
const redbtnSecondary = `
    background: transparent;
    color: #E4032B;
    border: solid 1px #E4032B;
`;

const redbtn = `
    background: transparent;
    color: #E4032B;
    border: none;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 0;
    padding: 0;
    & svg {
      margin-right: 5px;
    }
`;

export const ButtonLayout = styled.button`
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  outline: 0;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;

  & .link-btn {
    color: inherit;
    outline: 0;
    text-decoration: none;
  }
  & svg {
    margin-right: 6px;
  }
  ${(props) => {
    switch (props.color) {
      case "primary":
        return primary;
      case "secondary":
        return secondary;
      case "alternative":
        return alternative;
      case "success":
        return successButton;
      case "success_secondary":
        return successSecondary;
      case "red_express":
        return redbtn;
      case "red_secondary":
        return redbtnSecondary;
      default:
        return primary;
    }
  }}
`;
