import React from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id) => (
      <Link to={`/service-order/${id}`} style={{ color: "#000000a6" }}>
        {id}
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => {
      let color;
      let statusText = "";
      switch (status) {
        case "COMPLETED":
          color = "green";
          statusText = "Completada";
          break;
        case "PAYMENT":
          color = "volcano";
          statusText = "Anticipo generado";
          break;
        case "CREATED":
          color = "gold";
          statusText = "Creada";
          break;
        default:
          color = "geekblue";
          break;
      }
      return (
        <Tag color={color} key={status}>
          {statusText}
        </Tag>
      );
    },
    sortDirections: ["descend"],
  },

  {
    title: "Nº de Manifiesto",
    dataIndex: "manifestNumber",
    key: "manifestNumber",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha de creación",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt) => (
      <span>{moment(createdAt).format("MMM DD-hh:mm a")}</span>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Transportador",
    dataIndex: "Driver",
    key: "Driver",
    render: (Driver) => {
      return `${Driver?.User?.firstName} ${Driver?.User?.lastName}`;
    },

    sortDirections: ["descend"],
  },
  {
    title: "Monto de anticipo",
    dataIndex: "advancePercentage",
    key: "advancePercentage",
    render: (advancePercentage, item) => (
      <span>
        {new Intl.NumberFormat("de-DE").format(
          +(advancePercentage * item.total) / 100
        )}
      </span>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Valor de viaje",
    dataIndex: "total",
    key: "total",
    render: (total) => (
      <span>{new Intl.NumberFormat("de-DE").format(+total)}</span>
    ),
    sortDirections: ["descend"],
  },
];

export const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["Creada"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["Cancelada"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "4",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "5",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "6",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "7",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "8",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "9",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
  {
    key: "10",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["Completada"],
  },
];
