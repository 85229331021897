import React from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => (
      <Link to="/client/dsafds">
        <span style={{ color: "#000" }}>{text}</span>
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Razón Social",
    key: "name",
    dataIndex: "name",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "NIT",
    key: "nit",
    dataIndex: "nit",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "Teléfono",
    dataIndex: "phone",
    key: "phone",
    render: (text) => <span style={{ color: "#585858" }}>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Correo Electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },

  {
    title: "Acciones",
    dataIndex: "",
    key: "x",
    render: () => (
      <FlexContainerHelper justify="flex-start">
        <MdModeEdit size={18} style={{ marginRight: 15 }} />
        <MdDelete size={18} />
      </FlexContainerHelper>
    ),
  },
];
export function useSelectableRow() {
  //const [selectedRowKeys, setSelectedRowkeys] = useState([]);
  /*const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowkeys({ selectedRowKeys });
  };*/

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({}),
  };

  return rowSelection;
}

export const data = [
  {
    id: "1",
    socialReason: "Express - Logistica y Transporte C.",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "2",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "3",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "4",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "5",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "6",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "7",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "8",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "9",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: "10",
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
  {
    id: 11,
    socialReason: "Express - Logistica y Transporte C.A",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
    type: "Transportador propio",
  },
];
