import React from "react";
import styled from "styled-components";
import { LabelText } from "../../containers/dashboard-sa/Clients/NewClient";
import InvoicesItem from "./InvoicesItem";
import Spinner from "./Spinner";

const ProfileInvoices = ({ items, title, loadingInvoices, linkTo }) => {
  return (
    <>
      <LabelText style={{ marginBottom: 26 }}>{title}</LabelText>

      {loadingInvoices ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 440,
            overflowY: "auto",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <ScrolArea height={440}>
          {items.length > 0 ? (
            items.map((item, i) => (
              <InvoicesItem {...item} key={i} linkTo={linkTo} />
            ))
          ) : (
            <div>No hay Facturas disponibles.</div>
          )}
        </ScrolArea>
      )}
    </>
  );
};

const ScrolArea = styled.div`
  width: 100%;
  padding: 5px;
  height: ${(props) => `${props.height}px` || "100px"};
  overflow-y: auto;
`;

export default ProfileInvoices;
