import React from "react";

const Box = ({
  display,
  justifyContent,
  alignItems,
  marginRight,
  marginTop,
  marginLeft,
  marginBottom,
  width,
  height,
  flex,
  padding,
  flexDirection,
  position,
  children,
}) => (
  <div
    style={{
      display,
      justifyContent,
      alignItems,
      marginRight,
      marginTop,
      marginLeft,
      marginBottom,
      width,
      height,
      padding,
      flex,
      flexDirection,
      position,
    }}
  >
    {children}
  </div>
);

export default Box;
