import { axiosInstance } from "../utils/axios";

export const fetchInvoiceDetails = async (id, page, itemsPerPage) => {
  return axiosInstance.get(`/invoices/${id}`, {
    params: { page, itemsPerPage },
  });
};

export const fetchInvoices = async ({
  page,
  itemsPerPage,
  dateInitSearch,
  dateEndSearch,
  destinationId,
  destinationType,
}) => {
  return axiosInstance.get("/invoices", {
    params: {
      page,
      itemsPerPage,
      dateEndSearch,
      dateInitSearch,
      destinationId,
      destinationType,
    },
  });
};

export const fetchInvoiceTotal = async (destinationId, destinationType) => {
  return axiosInstance.get("/invoices/total", {
    params: { destinationId, destinationType },
  });
};

export const fetchInvoiceTotalsAllPartner = async (isPending) => {
  return axiosInstance.get("/invoices/total/partner/all", {
    params: { isPending },
  });
};

export const fetchInvoiceTotalsByOrga = async (organizationId) => {
  return axiosInstance.get(`/invoices/total/orga/${organizationId}`);
};

export const fetchInvoiceTotalsAllOrga = async () => {
  return axiosInstance.get("/invoices/total/orga/all");
};

export const fetchInvoiceTotalsByPartner = async (partnerId) => {
  return axiosInstance
    .get("/invoices/total/partner", {
      params: {
        partnerId,
      },
    })
    .then((res) => ({ data: res.data.result }));
};

export const getInvoiceTotalsByPartnerBranch = async (id) => {
  return axiosInstance.get(`/invoices/total/partner/branch/${id}`);
};

export const updateStatusInvoice = async (id, data) => {
  return axiosInstance
    .patch(`/invoices/${id}`, data)
    .then((res) => ({ data: res.data.result }));
};
