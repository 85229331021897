import { Timeline } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { DashboardCardText, Text } from "./dashboard-layout/styledComponents";

const ServiceOrderHistoryItem = ({
  index,
  item,
  currentUser,
  serviceOrder,
}) => {
  console.log(item);

  if (index === 0) {
    return (
      <Timeline.Item
        dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
        color="#00BE6D"
        key={index}
      >
        <DashboardCardText
          style={{
            width: 753,
            color: "#0A181D",
            marginBottom: 5,
          }}
        >
          Solicitud <strong>#{item.ServiceOrderId}</strong> creada por{" "}
          <strong>
            {`${currentUser?.firstName} ${currentUser?.lastName}` ||
              "Dashboard User"}{" "}
            -{" "}
            <a href={`mailto:${item?.data?.data.user}`}>
              {item?.data?.data.user}
            </a>
          </strong>{" "}
          al transportador <strong>#{item.data?.data?.driverIdNumber}</strong>, para
          el manifiesto <strong>#{serviceOrder?.manifestNumber}</strong> con un
          porcentaje de anticipo de <>{serviceOrder?.advancePercentage}%</>
        </DashboardCardText>
        <Text size={12} color="#949494" style={{ margin: 0 }}>
          {moment(item.createdAt).format("MMM DD - hh:mm a")}
        </Text>
      </Timeline.Item>
    );
  }

  if (index === 1) {
    return (
      <Timeline.Item
        dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
        color="#00BE6D"
        key={index}
      >
        <DashboardCardText
          style={{
            width: 753,
            color: "#0A181D",
            marginBottom: 5,
          }}
        >
          Anticipo generado por{" "}
          <strong>
            {`${currentUser?.firstName} ${currentUser?.lastName}` ||
              "Dashboard User"}{" "}
            -{" "}
            <a href={`mailto:${item?.data?.data.user}`}>
              {item?.data?.data.user}
            </a>
          </strong>{" "}
          al transportador <strong>#{item.data?.data?.driverIdNumber}</strong>, para
          el manifiesto <strong>#{serviceOrder?.manifestNumber}</strong> por un
          monto de{" "}
          <strong>
            $
            {new Intl.NumberFormat("de-DE").format(
              item.data?.data?.transactionValue
            )}
          </strong>
        </DashboardCardText>
        <Text size={12} color="#949494" style={{ margin: 0 }}>
          {moment(item.createdAt).format("MMM DD - hh:mm a")}
        </Text>
      </Timeline.Item>
    );
  }

  if (index > 1) {
    return (
      <Timeline.Item
        dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
        color="#00BE6D"
        key={index}
      >
        <DashboardCardText
          style={{
            width: 753,
            color: "#0A181D",
            marginBottom: 5,
          }}
        >
          Pago restante generado por{" "}
          <strong>
            {`${currentUser?.firstName} ${currentUser?.lastName}` ||
              "Dashboard User"}{" "}
            -{" "}
            <a href={`mailto:${item?.data?.data.user}`}>
              {item?.data?.data.user}
            </a>
          </strong>{" "}
          al transportador <strong>#{item.data?.data?.driverIdNumber}</strong>, para
          el manifiesto <strong>#{serviceOrder?.manifestNumber}</strong> por un
          monto de{" "}
          <strong>
            $
            {new Intl.NumberFormat("de-DE").format(
              item.data?.data?.transactionValue
            )}
          </strong>
        </DashboardCardText>
        <Text size={12} color="#949494" style={{ margin: 0 }}>
          {moment(item.createdAt).format("MMM DD - hh:mm a")}
        </Text>
      </Timeline.Item>
    );
  }

  return null;
};

export default ServiceOrderHistoryItem;
