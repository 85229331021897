import React from "react";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { MdModeEdit } from "react-icons/md";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
    sortDirections: ["descend"],
  },

  {
    title: "Correo Electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Tipo de Usuario",
    dataIndex: "user_type",
    key: "user_type",
    render: (text) => (
      <span style={{ textTransform: "capitalize" }}>{text}</span>
    ),

    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    dataIndex: "",
    key: "x",
    render: () => (
      <FlexContainerHelper justify="space-around">
        <MdModeEdit size={18} />
        {/* <MdDelete size={18} /> */}
      </FlexContainerHelper>
    ),
  },
];
export function useSelectableRow() {
  //const [selectedRowKeys, setSelectedRowkeys] = useState([]);
  /*const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowkeys({ selectedRowKeys });
  };*/

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({}),
  };

  return rowSelection;
}

export const data = [
  {
    id: 1,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 2,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 10,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 3,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 4,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 5,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 6,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 7,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 8,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
  {
    id: 9,
    name: "Abraham Sánchez",
    email: "abraham@byyuto.com",
    user_type: "Administrador",
  },
];
