import React, { useState } from "react";
// import { Switch } from "antd";
import { Link } from "react-router-dom";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { MdModeEdit, MdBlock, MdDelete } from "react-icons/md";
// import styled, { css } from "styled-components";
import { IoMdArchive } from "react-icons/io";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { updateProduct } from "../../../services/products.service";

// const SwitchBlue = styled(Switch)`
//   & .ant-switch-handle::before {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: ${(props) => (props.active ? "#1645b8" : "#fff")};
//     border-radius: 50%;
//     -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
//     box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
//     -webkit-transition: all 0.2s ease-in-out;
//     transition: all 0.2s ease-in-out;
//     content: "";
//   }
//   & .ant-switch-handle {
//     width: 25px;
//     height: 25px;
//     top: -3px;
//     ${(props) =>
//       props.active
//         ? ""
//         : css`
//             left: 0;
//           `};
//   }
//
//   background-color: ${(props) => (props.active ? "#E8EEFC" : "#747474")};
//   height: 18px;
// `;

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id, record) => (
      <Link
        to={`/ally/${record.partnerId}/branches/${id}`}
        style={{ color: "#000000a6" }}
      >
        {id}
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",

    sortDirections: ["descend"],
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    key: "phone",

    sortDirections: ["descend"],
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",

    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    key: "action",
    render: (text, record) => (
      <FlexContainerHelper justify="space-around">
        <MdModeEdit size={18} />
        <MdBlock size={18} />
        <MdDelete size={18} />
      </FlexContainerHelper>
    ),
  },
];
export const columnsTransactions = [
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",

    sortDirections: ["descend"],
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha/Hora",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "mount",
    key: "mount",

    sortDirections: ["descend"],
  },
];
export const columnsProduct = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",

    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    sortDirections: ["descend"],
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",

    sortDirections: ["descend"],
  },
  {
    title: "Nombre de la unidad",
    dataIndex: "unitName",
    key: "unitName",

    sortDirections: ["descend"],
  },
  {
    title: "Comisión",
    dataIndex: "commission",
    key: "commission",

    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    key: "action",
    dataIndex: "action",
    render: (component, record) => {
      return record.status !== "DISABLED" ? component : null;
    },
  },
];

export const ArchiveActionButton = ({ productId, setChangeTable }) => {
  const [loading, setLoading] = useState(false);
  const archivedProduct = async (productId) => {
    const confirmArchivedProduct = window.confirm(
      "Al archivar este producto se elimina a la vista de todos los usuarios, pero se mantienen todas las transacciones hechas anteriormente con este producto. ¿Estás seguro que quieres archivar este producto?"
    );
    if (confirmArchivedProduct) {
      setLoading(true);
      try {
        await updateProduct(productId, { status: "DISABLED" });
        setChangeTable(true);
        setLoading(false);
      } catch (e) {
        console.log(e.response || e);
        setLoading(false);
      }
    } else {
      return;
    }
  };
  return loading ? (
    <Spinner />
  ) : (
    <IoMdArchive onClick={() => archivedProduct(productId)} />
  );
};
