import { axiosInstance } from "../utils/axios";

export const createProduct = async (data) => {
  return axiosInstance.post("/products", data).then((res) => res.data);
};

export const buyProduct = async (data) => {
  return axiosInstance.post("/products/buy", data).then((res) => res.data);
};

export const updateProduct = async (id, data) => {
  return axiosInstance.patch(`/products/${id}`, data).then((res) => res.data);
};

export const fetchProducts = async (params) => {
  return axiosInstance
    .get("/products", {
      params: {
        ...params,
      },
    })
    .then((res) => ({ data: res.data.result }));
};
