import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { toast } from "react-toastify";
import { Select } from "antd";

import {
  InputLayout,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import useInput from "../../../hooks/useInput";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { SelectLayout } from "../../../components/dashboard-layout/styledComponents";
import { inviteDriver } from "../../../services/invitations.service";

const { Option } = Select;

const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRgx = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
function TransporterForm({ modalSetting, updateDrivers }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = useInput("", isSubmitting);
  const name = useInput("", isSubmitting);
  const phone = useInput("", isSubmitting);
  const lastName = useInput("", isSubmitting);
  const [driverType, setDriverType] = useState("OWN");

  const cleanForm = () => {
    email.setValue("");
    email.setError("");
    name.setValue("");
    name.setError("");
    lastName.setValue("");
    lastName.setError("");
    phone.setValue("");
    phone.setError("");
  };

  useEffect(() => {
    if (modalSetting.visible) {
      cleanForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSetting.visible]);

  const onInviteDriver = async () => {
    setIsSubmitting(true);
    if (!name.value || !lastName.value || !driverType) {
      return;
    }

    if (driverType === "OWN" && !phone.value) {
      return;
    }
    if (driverType === "OWN" && !phoneRgx.test(phone.value)) {
      phone.setError("Por favor, Ingresa un teléfono válido");
      return;
    }

    if (driverType === "OUTSOURCED" && !email.value) {
      return;
    }

    if (driverType === "OUTSOURCED" && !emailRgx.test(email.value)) {
      email.setError("Por favor, Ingresa un Correo electrónico válido");
      return;
    }

    if (driverType === "OWN" && !phone.value.includes("+57")) {
      phone.setError(
        "Por favor agregue +57 al principio del numero de telefono."
      );
      return;
    }

    try {
      setLoading(true);
      const response = await inviteDriver({
        firstName: name.value,
        lastName: lastName.value,
        email: email.value,
        variant: driverType,
        ...(phone.value !== "" ? { phoneNumber: phone.value } : {}),
      });
      if (response && response.data) {
        updateDrivers(response.data);
        toast.success(
          `El transportador con el correo ${email?.value} se ha creado con éxito.`
        );
        cleanForm();
        modalSetting.handleCancel();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.message.includes("already invited")) {
        toast.error(
          `El correo electrónico "${email.value}" ya fue invitado. Por favor, verifícalo.`
        );
        return;
      }

      if (error.message.includes("already exists")) {
        toast.error(
          `El correo electrónico "${email.value}" ya se encuentra registrado. Por favor, verifícalo.`
        );
        return;
      }

      if (error.message.includes("belongs to another organization")) {
        toast.error("Este transportador pertenece a otra organización.");
        return;
      }

      return;
    }
  };

  const onChangeDriverType = (value) => {
    phone.setError("");
    phone.setValue("");
    setDriverType(value);
  };

  return (
    <DashboardModal
      {...modalSetting}
      handleCancel={() => {
        modalSetting.handleCancel();
        cleanForm();
      }}
      footer={null}
    >
      <p>
        Para agregar un transportador ingresa el correo electrónico, se le enviara
        una invitación para que acceda a la aplicación.
      </p>
      <Row gutter={6} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <label>Tipo de transportador:</label>
          <SelectLayout onChange={onChangeDriverType} value={driverType}>
            <Option value="OWN">Propio</Option>
            <Option value="OUTSOURCED">Tercero</Option>
          </SelectLayout>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <label>Nombre</label>
          <InputLayout
            type="text"
            placeholder="Ingresar nombre"
            size="large"
            value={name.value}
            onChange={(e) => {
              name.onChange(e);
            }}
          />
          <ErrorMessage error={!!name.error}>{name.error}</ErrorMessage>
        </Col>
        <Col span={12}>
          <label>Apellido</label>
          <InputLayout
            type="text"
            placeholder="Ingresar nombre"
            size="large"
            value={lastName.value}
            onChange={(e) => {
              lastName.onChange(e);
            }}
          />
          <ErrorMessage error={!!lastName.error}>{lastName.error}</ErrorMessage>
        </Col>
      </Row>
      <Row>
        <label>Correo Electrónico del transportador</label>
        <InputLayout
          type="email"
          placeholder="Ingresar correo electrónico"
          size="large"
          value={email.value}
          onChange={(e) => {
            email.onChange(e);
          }}
        />
        <ErrorMessage error={!!email.error}>{email.error}</ErrorMessage>
      </Row>
      {driverType === "OWN" && (
        <Row>
          <label>Teléfono del transportador</label>
          <InputLayout
            type="text"
            placeholder="Ingresar teléfono"
            size="large"
            value={phone.value}
            onChange={(e) => {
              phone.onChange(e);
            }}
          />
          <ErrorMessage error={!!phone.error}>{phone.error}</ErrorMessage>
        </Row>
      )}
      <div>
        <FlexContainerHelper justify="flex-end">
          <Button
            type="alternative"
            actionButton={() => {
              cleanForm();
              modalSetting.handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" actionButton={onInviteDriver}>
            {loading ? <Spinner color="#ffF" /> : "Enviar invitación"}
          </Button>
        </FlexContainerHelper>
      </div>
    </DashboardModal>
  );
}

export default TransporterForm;
