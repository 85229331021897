import React from "react";
import moment from "moment";
import { StatusItem } from "./styled-components";

const isBranchActive = (status) => {
  switch (status) {
    case "COMPLETED":
      return (
        <StatusItem status="success">
          <span>Pagada</span>
        </StatusItem>
      );
    case "PENDING":
      return (
        <StatusItem status="warning">
          <span>Pago pendiente</span>
        </StatusItem>
      );
    default:
      return (
        <StatusItem status="warning">
          <span>Pago pendiente</span>
        </StatusItem>
      );
  }
};

export const columnsWithdrawal = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortDirections: ["descend"],
  },
  {
    title: "Valor a consignar",
    dataIndex: "value",
    key: "value",
    render: (record) => {
      return <> {`$ ${new Intl.NumberFormat("de-DE").format(record)}`}</>;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    render: (record) => <>{isBranchActive(record)}</>,
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    dataIndex: "user",
    key: "user",
    render: (user) => (
      <>
        <span>{`${user.firstName} ${user.lastName}` || "-"}</span>
      </>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Cédula Driver",
    dataIndex: "idNumber",
    key: "idNumber",
    render: (data) => (
      <>
        <span>{data || "-"}</span>
      </>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Organización",
    dataIndex: "organization",
    key: "organization",
    render: (organization) => {
      return <span>{organization || "-"}</span>;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Fecha de solicitud",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date) => {
      return <>{moment(date).format("MMM DD, YYYY  hh:mm a") || "-"}</>;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Fecha de pago",
    dataIndex: "paidAt",
    key: "paidAt",
    render: (date) => {
      return <>{date ? moment(date).format("MMM DD, YYYY  hh:mm a") : "-"}</>;
    },
    sortDirections: ["descend"],
  },
];
