import React, { useState, useEffect } from "react";
import {
  DashboardCardTitle,
  FlexContainerHelper,
  DashboardSectionTitle,
} from "../../../components/dashboard-layout/styledComponents";
import {
  WithdrawalDetailLayout,
  BreadcrumItem,
  DashboardSaCard,
  SelectStatus,
  UploadReceiptLayout,
} from "./styled-components";
import { Breadcrumb } from "antd";
import { HeaderContainer } from "../Allies/AllyProfile";
import { useParams } from "react-router-dom";
import { Row, Col, Collapse } from "antd";
import moment from "moment";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { toast } from "react-toastify";
import { AiOutlineFile } from "react-icons/ai";
import {
  fetchPaymentIntentDetails,
  updatePaymentIntents,
} from "../../../services/payments.service";

const { Panel } = Collapse;

export default function WithdrawalDetail() {
  const { withdrawalId } = useParams();
  const [withdrawal, setWithdrawal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [statusSelected, setStatusSelected] = useState("PENDING");
  const getWithdrawalRequest = async (requestId) => {
    setLoading(true);
    try {
      const response = await fetchPaymentIntentDetails(requestId);
      setWithdrawal(response.data.result);
      setLoading(false);
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  };

  const handleUploadReceipt = async (e) => {
    e.preventDefault();
    if (withdrawal?.status === "COMPLETED" && withdrawal?.urlVoucherImage)
      return;

    setUploadingFile(true);
    try {
      const file = await getBase64File(e.target.files[0]);
      const response = await updatePaymentIntents(withdrawalId, {
        imageBase64: file,
      });
      setWithdrawal({
        ...withdrawal,
        urlVoucherImage: response.data.result.urlVoucherImage,
        status: response.data.result.status,
        paidAt: response.data.result.paidAt,
      });
      toast.success("Pago realizado satisfactoriamente!");
      setUploadingFile(false);
    } catch (e) {
      console.log(e);
      toast.error(
        `Error: ${
          e.message || e.response?.error || "Ha ocurrido un error inesperado"
        }`
      );
      setUploadingFile(false);
    }
  };

  const getBase64File = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader(file);
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    getWithdrawalRequest(withdrawalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (withdrawal && withdrawal?.status) {
      setStatusSelected(withdrawal?.status || "PENDING");
    }
  }, [withdrawal]);
  return (
    <WithdrawalDetailLayout>
      <HeaderContainer>
        <div>
          <DashboardSectionTitle>Solicitud de retiro</DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/">
              Dashboard
            </BreadcrumItem>
            <BreadcrumItem>Solicitud de retiro #ID{withdrawalId}</BreadcrumItem>
          </Breadcrumb>
        </div>
      </HeaderContainer>
      <DashboardSaCard height="651px" style={{ padding: "20px 30px" }}>
        {!loading && (
          <>
            <DashboardCardTitle>
              Solicitud de retiro ID#{withdrawalId}
            </DashboardCardTitle>
            <Row align="middle">
              <Col span={5}>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Transportador:</strong>
                  </span>
                  <span>
                    {`${withdrawal?.User?.firstName} ${withdrawal?.User?.lastName}` ||
                      "-"}
                  </span>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Organización:</strong>
                  </span>
                  <span>
                    {withdrawal?.User?.Driver?.Organization?.name || "-"}
                  </span>
                </FlexContainerHelper>
              </Col>
              <Col span={6} offset={1}>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Fecha de Solicitud:</strong>
                  </span>
                  <span>
                    {moment(withdrawal?.createdAt).format(
                      "MMM DD, YYYY  hh:mm a"
                    ) || "-"}
                  </span>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Fecha de pago:</strong>
                  </span>
                  <span>
                    {withdrawal?.paidAt
                      ? moment(withdrawal?.paidAt).format(
                          "MMM DD, YYYY  hh:mm a"
                        )
                      : "-"}
                  </span>
                </FlexContainerHelper>
              </Col>
              <Col span={4} offset={7}>
                <FlexContainerHelper
                  direction="column"
                  align="flex-end"
                  className="amount-status-container"
                >
                  <span>Valor a consignar</span>
                  <DashboardSectionTitle className="withdrawal-amount">
                    {`$ ${new Intl.NumberFormat("de-DE").format(
                      withdrawal?.value
                    )}`}
                  </DashboardSectionTitle>
                  <div>
                    <SelectStatus
                      currentStatus={statusSelected}
                      onChange={(value) => setStatusSelected(value)}
                      options={
                        withdrawal?.status === "COMPLETED" &&
                        withdrawal?.urlVoucherImage
                          ? [{ label: "Pagado", value: "COMPLETED" }]
                          : [
                              {
                                label: "Pendiente por pago",
                                value: "PENDING",
                              },
                              { label: "Pagado", value: "COMPLETED" },
                            ]
                      }
                      defaultValue={statusSelected}
                      dropdownClassName="payment-status"
                      inputProps={{
                        readOnly:
                          statusSelected === "COMPLETED" &&
                          !!withdrawal?.urlVoucherImage,
                      }}
                    />
                  </div>
                </FlexContainerHelper>
              </Col>
            </Row>
            {statusSelected === "COMPLETED" && !withdrawal?.urlVoucherImage && (
              <Row>
                <Col span={24}>
                  <UploadReceiptLayout>
                    <h4>Comprobante de la transferencia</h4>
                    {!uploadingFile ? (
                      <div className="upload-receipt-component">
                        <p>
                          Arrastre y suelte su archivo aquí o presione el botón
                          para cargar
                        </p>
                        <button>Adjuntar archivo</button>
                        <input
                          accept=".png,.jpg,application/pdf"
                          type="file"
                          onChange={handleUploadReceipt}
                        />
                        <span>
                          Solo .png .jpg . Pdf con un tamaño max del archivo de
                          2MB
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 200,
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </UploadReceiptLayout>
                </Col>
              </Row>
            )}
            {statusSelected === "COMPLETED" && withdrawal?.urlVoucherImage && (
              <Row className="file-container" align="middle">
                <Col span={24}>
                  <h4>Comprobante de la transferencia</h4>
                </Col>
                <Col span={12}>
                  <AiOutlineFile color="#20BEA5" size={24} />
                  <span className="file-name">
                    <strong>Comprobante cargado</strong>
                  </span>
                </Col>
                <Col span={12} flex justify="end">
                  {withdrawal?.urlVoucherImage && (
                    <a
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="download-receipt"
                      href={withdrawal.urlVoucherImage}
                    >
                      Ver comprobante
                    </a>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24} className="collapse-container">
                <Collapse defaultActiveKey={["1"]}>
                  <Panel header="Información básica" key="1">
                    <Row>
                      <Col span={5}>
                        <span>
                          <strong>Cédula: </strong>
                          {withdrawal?.User?.Driver?.idNumber || "-"}
                        </span>
                      </Col>
                      <Col span={6} offset={1}>
                        <span>
                          <strong>Número de celular: </strong>
                          {withdrawal?.User?.Driver?.phoneNumber || "-"}
                        </span>
                      </Col>
                      <Col span={6} offset={1}>
                        <span>
                          <strong>Correo: </strong>
                          {withdrawal?.User?.email || "-"}
                        </span>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="collapse-container">
                <Collapse defaultActiveKey={["1"]}>
                  <Panel header="Información bancaria" key="1">
                    {withdrawal?.User?.Driver?.BankAccount ? (
                      <>
                        <Row>
                          <span style={{ color: "#1CA892" }}>
                            <strong>Cuenta 1</strong>
                          </span>
                        </Row>
                        <Row>
                          <Col span={5}>
                            <span>
                              <strong>Nombre del banco: </strong>
                              {withdrawal?.User?.Driver?.BankAccount?.BankId ||
                                "-"}
                            </span>
                          </Col>
                          <Col span={6} offset={1}>
                            <span>
                              <strong>Tipo de cuenta: </strong>
                              {withdrawal?.User?.Driver?.BankAccount
                                ?.accountType || "-"}
                            </span>
                          </Col>
                          <Col span={8} offset={1}>
                            <span>
                              <strong>Número de cuenta: </strong>
                              {withdrawal?.User?.Driver?.BankAccount
                                ?.accountNumber || "-"}
                            </span>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <div>Este usuario no ha registrado cuenta bancaria.</div>
                    )}
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Spinner />
          </div>
        )}
      </DashboardSaCard>
    </WithdrawalDetailLayout>
  );
}
