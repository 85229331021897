import React, { useState, useEffect, useCallback } from "react";
import { BsFillPeopleFill } from "react-icons/bs";
import { DatePicker, Row, Col } from "antd";
import { FaShoppingBag } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { HeaderContainer } from "../Allies/AllyProfile";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { columnsWithdrawal } from "./utils";
import SearchBar from "../../../components/dashboard-layout/search-bar/SearchBar";
import { fetchPaymentIntents } from "../../../services/payments.service";
import {
  fetchInvoiceTotalsAllOrga,
  fetchInvoiceTotalsAllPartner,
} from "../../../services/invoices.service";
const { RangePicker } = DatePicker;

const HomeSA = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [amountTotalOrg, setAmountTotalOrg] = useState(null);
  const [amountTotalPartner, setAmountTotalPartner] = useState(null);
  const history = useHistory();
  const [nameFilter, setNameFilter] = useState(undefined);
  const [idFilter, setIdFilter] = useState(undefined);
  const onChangePage = (val) => {
    if (val) {
      setPaginationWithdrawal((paginationWithdrawal) => ({
        ...paginationWithdrawal,
        page: val,
      }));
    }
  };
  const [paginationWithdrawal, setPaginationWithdrawal] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);

  const renderWithdrawRequest = useCallback(async () => {
    setLoading(true);

    try {
      const response = await fetchPaymentIntents({
        page: paginationWithdrawal.page,
        itemsPerPage: paginationWithdrawal.pageSize,
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
        type: "ORGANIZATION",
        ...(idFilter !== "" ? { idNumber: idFilter } : {}),
        ...(nameFilter !== "" ? { name: nameFilter } : {}),
      });
      const formattedData = response.data.map((withdrawal) => {
        return {
          id: withdrawal?.id,
          value: withdrawal?.value,
          status: withdrawal?.status,
          user: withdrawal?.User || "-",
          idNumber: withdrawal?.User?.Driver?.idNumber || "-",
          organization: "-",
          createdAt: withdrawal?.createdAt,
          paidAt: withdrawal?.paidAt,
        };
      });
      setWithdrawals(formattedData);
      setPaginationWithdrawal((paginationWithdrawal) => ({
        ...paginationWithdrawal,
        page: response.pagination.currentPage,
        total: response.pagination.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [paginationWithdrawal, dateRange, idFilter, nameFilter]);

  const getIndicatorsValues = async () => {
    setLoadingIndicators(true);
    try {
      const responseOrganizations = await fetchInvoiceTotalsAllOrga();
      const responsePartner = await fetchInvoiceTotalsAllPartner(true);
      setAmountTotalOrg(responseOrganizations.data.result);
      setAmountTotalPartner(responsePartner.data.result);
      setLoadingIndicators(false);
    } catch (e) {
      console.log(e);
      setLoadingIndicators(false);
    }
  };

  const onChangeNameFilter = (e) => {
    setNameFilter(e.target.value?.trim());
  };
  const onChangeIdFilter = (e) => {
    setIdFilter(e.target.value?.trim());
  };

  useEffect(() => {
    renderWithdrawRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationWithdrawal.page,
    paginationWithdrawal.pageSize,
    dateRange,
    idFilter,
    nameFilter,
  ]);

  useEffect(() => {
    getIndicatorsValues();
  }, []);

  return (
    <>
      <HeaderContainer>
        <DashboardSectionTitle style={{ marginTop: 12 }}>
          Dashboard
        </DashboardSectionTitle>
      </HeaderContainer>
      <Row justify="end">
        <RangePicker
          defaultValue={[moment().subtract(30, "days"), moment()]}
          ranges={{
            Hoy: [moment(), moment()],
            "Mes en curso": [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            Semana: [moment().startOf("week"), moment().endOf("week")],
            "Últimos 30 días": [moment().subtract(30, "days"), moment()],
          }}
          onChange={(dates) =>
            setDateRange([
              moment(dates[0]).startOf("day").format(),
              moment(dates[1]).endOf("day").format(),
            ])
          }
        />
      </Row>
      {amountTotalOrg && amountTotalPartner ? (
        <Row justify="end">
          <Col span={8} style={{ width: 338, marginRight: 25 }}>
            <DashboardItem
              title="Total pendiente facturado por Aliados"
              value={`$ ${new Intl.NumberFormat("de-DE").format(
                amountTotalPartner
              )}`}
              factor=""
              icon={<FaShoppingBag />}
              iconColor="#F84B05"
              iconBg="#F84B0512"
              factorStatus="up"
              minheight="80px"
            />
          </Col>
          <Col span={8}>
            <DashboardItem
              title="Total pendiente facturado por Clientes"
              value={`$ ${new Intl.NumberFormat("de-DE").format(
                amountTotalOrg
              )}`}
              factor=""
              icon={<BsFillPeopleFill />}
              iconColor="#00BE6D"
              iconBg="#E5F9F0"
              factorStatus="down"
              minheight="80px"
            />
          </Col>
        </Row>
      ) : null}
      <br />
      <DashboardTable
        columns={columnsWithdrawal}
        tableTitle="Solicitudes de retiro"
        data={withdrawals}
        loading={loading}
        onClickRow={(val, record) => {
          history.push(`/withdrawals/${record.id}`);
        }}
        page={paginationWithdrawal.page}
        pagination={paginationWithdrawal}
        onChangePage={onChangePage}
        onChangeItemsPerPage={(page, size) =>
          setPaginationWithdrawal((pagination) => ({
            ...pagination,
            pageSize: size,
          }))
        }
        searchBarPlaceholder="Buscar por cédula de transportador"
        customButton={
          <>
            <SearchBar
              pholder={"Buscar por nombre de transportador"}
              bcolor={"#F6F9FE"}
              elmwidth="40%"
              onChange={onChangeNameFilter}
            />
          </>
        }
        onChangeSearchBar={onChangeIdFilter}
      />
    </>
  );
};

export default HomeSA;
