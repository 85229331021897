import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { StatusItem } from "./InvoicesItem";
import { Text } from "../../containers/dashboard-sa/Clients/NewClient";

const OrderItem = ({ data }) => {
  const getServiceOrderStatus = (status) => {
    switch (status) {
      case "CREATED":
        return <StatusItem status="error">Creada</StatusItem>;
      case "PAYMENT":
        return <StatusItem status="warning">Anticipo Generado</StatusItem>;
      case "COMPLETED":
        return <StatusItem status="success">Completado</StatusItem>;
      default:
        return <StatusItem status="success">Completado</StatusItem>;
    }
  };

  return (
    <OrderWrapper>
      <Row justify="space-between">
        <Col>
          <Text color="#0A181D" style={{ margin: 0, fontWeight: 600 }}>
            {`Orden de servicio #${data?.id}`}
          </Text>
          <Text style={{ margin: 0 }}>{`Manifiesto: ${
            data?.manifestNumber || ""
          }`}</Text>
        </Col>
        <Col>{getServiceOrderStatus(data?.status) || "No disponible"}</Col>
      </Row>
    </OrderWrapper>
  );
};

const OrderWrapper = styled.div`
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #76889a29;
  margin-bottom: 15px;
`;

export default OrderItem;
