import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Row, Col } from "antd";
import { BsFillPeopleFill } from "react-icons/bs";

import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import { useSelectableRow } from "../../dashboard/transporters/tableConfig";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import TransporterForm from "../../dashboard/transporters/TransporterForm";
import { StatusItem } from "../../../components/dashboard-SA/InvoicesItem";
import { Context } from "../../../Context";
import usePagination from "../../../hooks/usePagination";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import {
  fetchInvoices,
  fetchInvoiceTotalsAllOrga,
} from "../../../services/invoices.service";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "Fecha de creación",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Cliente",
    dataIndex: "client",
    key: "client",

    sortDirections: ["descend"],
  },
  {
    title: "Rango de Facturación",
    dataIndex: "range",
    key: "range",

    sortDirections: ["descend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ current, text }) => (
      <StatusItem status={current}>{text}</StatusItem>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "amount",
    key: "amount",
    sortDirections: ["descend"],
  },
];

const getTagColor = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "error";
  }

  if (status === "PENDING_PAY") {
    return "warning";
  }

  if (status === "PAID") {
    return "success";
  }
};
const getTagText = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "Pendiente por verificar";
  }

  if (status === "PENDING_PAY") {
    return "Pendiente por pago";
  }

  if (status === "PAID") {
    return "Pagado";
  }
};

const OrganizationInvoices = ({ history }) => {
  const rowSelectable = useSelectableRow();
  const modal = useDashboardModal();
  const { currentUser } = useContext(Context);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amountTotalOrg, setAmountTotalOrg] = useState(null);
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const getIndicatorsValues = async () => {
    setLoadingIndicators(true);
    try {
      const responseOrganizations = await fetchInvoiceTotalsAllOrga();
      setAmountTotalOrg(responseOrganizations.data.result);
      setLoadingIndicators(false);
    } catch (e) {
      console.log(e);
      setLoadingIndicators(false);
    }
  };
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();

  useEffect(() => {
    const onGetInvoices = async () => {
      try {
        setLoading(true);

        const { data } = await fetchInvoices({
          page,
          itemsPerPage,
          dateInitSearch: dateRange[0],
          dateEndSearch: dateRange[1],
          destinationType: "ORGANIZATION",
        });
        const formatedRows = data?.data?.map((row) => ({
          id: row.id,
          date: moment(row.createdAt).format("DD/MM/YYYY"),
          client: row?.destination?.name,
          range: `${moment(row.initDate.split(".")[0]).format(
            "MMMM DD, hh:mm a"
          )} - ${moment(row.endDate.split(".")[0]).format("MMMM DD, hh:mm a")}`,
          amount: `$ ${new Intl.NumberFormat("de-DE").format(row.value)}`,
          status: {
            current: getTagColor(row.status),
            text: getTagText(row.status),
          },
        }));
        setPagination(data?.pagination);
        setInvoices(formatedRows);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (currentUser) {
      onGetInvoices();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, page, itemsPerPage, dateRange]);

  useEffect(() => {
    getIndicatorsValues();
  }, []);

  const onClickRow = (e, row) => {
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/invoice/${row.id}?Org`);
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <DashboardSectionTitle>Facturas a Clientes</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
          <Row justify="end">
            <DashboardItem
              title="Total pendiente facturado por Clientes"
              value={`$ ${new Intl.NumberFormat("de-DE").format(
                amountTotalOrg
              )}`}
              factor=""
              icon={<BsFillPeopleFill />}
              iconColor="#00BE6D"
              iconBg="#E5F9F0"
              factorStatus="down"
              loading={loadingIndicators}
              minheight="80px"
            />
          </Row>
        </Col>
      </Row>
      <DashboardTable
        columns={columns}
        pagination={pagination}
        rowSelectable={rowSelectable}
        tableTitle="Lista de Facturas"
        buttonActionText="+ Crear transportador"
        data={invoices}
        page={page}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        loading={loading}
        onClickRow={onClickRow}
      />
      <TransporterForm modalSetting={modal} />
    </>
  );
};

export default OrganizationInvoices;
