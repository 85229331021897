import { axiosInstance } from "../utils/axios";

export const createPartner = async (data) => {
  return axiosInstance.post("/partners", data).then((res) => res.data);
};

export const updatePartner = async (id, data) => {
  return axiosInstance.put(`/partners/${id}`, data).then((res) => res.data);
};

export const createPartnerBranch = async (data) => {
  return axiosInstance.post("/partners/branch", data).then((res) => res.data);
};

export const updatePartnerBranch = async (id, data) => {
  return axiosInstance
    .put(`/partners/branch/${id}`, data)
    .then((res) => res.data);
};

export const fetchPartnerBranches = async (page, itemsPerPage, nameFilter) => {
  return axiosInstance.get("/partners/branches", {
    params: {
      page,
      itemsPerPage,
      nameFilter,
    },
  });
};

export const fetchPartnerBranch = async (partnerBranchId) => {
  return axiosInstance.get("/partners/branch", {
    params: {
      partnerBranchId,
    },
  });
};

export const fetchPartnerBranchUsers = async (params) => {
  return axiosInstance
    .get("/partners/branch/users", {
      params: {
        ...params,
      },
    })
    .then((res) => res.data);
};

export const fetchPartnerBranchNear = async (data) => {
  return axiosInstance
    .get("/partners/branch/near", data)
    .then((res) => res.data);
};

export const fetchPartner = async (partnerId) => {
  return axiosInstance.get("/partners/detail", {
    params: {
      partnerId,
    },
  });
};
