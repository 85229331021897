import React from "react";
import RegisterForm from "./RegisterForm";
import {
  ScreenTitle,
  MainTitle,
  AuthText,
  AuthCard,
} from "../../../components/auth-layout/styledComponents";
import { BsArrowRight } from "react-icons/bs";
import { RowLogin, CardLinkLogin, AuthTextLogin } from "../login/login";

export default function Register() {
  return (
    <AuthCard>
      <ScreenTitle>Registro</ScreenTitle>
      <MainTitle>Bienvenido a HOY+</MainTitle>
      <AuthText>
        Por favor ingresa tu Correo electrónico para continuar con el registro.
      </AuthText>
      <RegisterForm />
      <RowLogin gutter={{ xs: 4 }} justify="end" align="center">
        <AuthTextLogin>¿Tienes cuenta? </AuthTextLogin>
        <CardLinkLogin to="/login"> Inicia sesión</CardLinkLogin>
        <BsArrowRight color="#1645b8" size={20} style={{ marginLeft: 5 }} />
      </RowLogin>
    </AuthCard>
  );
}
