import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Tabs, DatePicker } from "antd";
import {
  DashboardSectionTitle,
  FlexContainerHelper,
  DashboardCard,
} from "../../../components/dashboard-layout/styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import { MdBlock, MdDelete } from "react-icons/md";
import Orders from "./orders/Orders";
import Profile from "./Profile";
import { BreadcrumItem } from "../../dashboard-sa/Clients/NewClient";
import styled from "styled-components";
import TransporterTransactions from "./TransporterTransactions";
import moment from "moment";
import { fetchDriverInfo } from "../../../services/drivers.service";
import { fetchServiceOrders } from "../../../services/services.service";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

export default function TransporterDetail({ match }) {
  const [orderServices, setOrderServices] = useState([]);
  const [driver, setDriver] = useState(null);
  const [loadingDriver, setLoadingDriver] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);

  const onGetDriverInfo = useCallback(async () => {
    try {
      setLoadingDriver(true);
      const data = await fetchDriverInfo(match.params.id);
      setDriver(data);
      setLoadingDriver(false);
    } catch (error) {
      setLoadingDriver(false);
      console.log(error);
    }
  }, [match]);

  const getServiceOrders = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchServiceOrders({
        page: 1,
        itemsPerPage: 50,
        driverId: match.params.id,
      });
      setOrderServices(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [match]);

  useEffect(() => {
    getServiceOrders();
    onGetDriverInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <div>
            <DashboardSectionTitle>
              Perfil del transportador ID #{match.params.id}
            </DashboardSectionTitle>
            <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
              <BreadcrumItem active href="/transporter">
                Transportadores
              </BreadcrumItem>
              <BreadcrumItem>Perfil del transportador</BreadcrumItem>
            </Breadcrumb>
          </div>
        </Col>
        <Col span={12}>
          {currentTab === 2 && (
            <Row justify="end">
              <RangePicker
                defaultValue={[moment().subtract(30, "days"), moment()]}
                ranges={{
                  Hoy: [moment(), moment()],
                  "Mes en curso": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  Semana: [moment().startOf("week"), moment().endOf("week")],
                  "Últimos 30 días": [moment().subtract(30, "days"), moment()],
                }}
                onChange={(dates) =>
                  setDateRange([
                    moment(dates[0]).startOf("day").format(),
                    moment(dates[1]).endOf("day").format(),
                  ])
                }
              />
            </Row>
          )}
          {currentTab === 1 && (
            <Row justify="end">
              <FlexContainerHelper justify="space-around">
                <Button type="success" icon={<MdDelete size={18} />}>
                  Eliminar perfil
                </Button>
                <Button type="success_secondary" icon={<MdBlock size={18} />}>
                  Bloquear perfil
                </Button>
              </FlexContainerHelper>
            </Row>
          )}
        </Col>
      </Row>
      <DashboardCard direction="column">
        <Tabss
          defaultActiveKey="1"
          onChange={(activeKey) => setCurrentTab(parseInt(activeKey))}
        >
          <TabPane tab="Perfil" key="1">
            <Row>
              <Col
                span={12}
                style={{
                  borderRight: "solid 1px #e3e3e3",
                  height: "65vh",
                  overflow: "hidden",
                }}
              >
                <Profile loading={loadingDriver} driver={driver} />
              </Col>
              <Col
                span={12}
                style={{
                  borderLeft: "solid 1px #e3e3e3",
                  height: "65vh",
                  overflow: "hidden",
                }}
              >
                <Orders loading={loading} orderServices={orderServices} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Transacciones" key="2">
            <TransporterTransactions dateRange={dateRange} />
          </TabPane>
        </Tabss>
      </DashboardCard>
    </>
  );
}

export const Tabss = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 500;
    min-width: 100px !important;
    text-align: center !important;
  }
`;
