import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { signOut } from "./services/firebase";
import { fetchMe } from "./services/me.service";
import { SESSION_DATE_KEY, TOKEN_KEY } from "./utils/constants";

export const Context = createContext();

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const value = {
    isAuth,
    authUser,
    currentUser,
    setIsAuth,
    setAuthUser,
    removeAuth: () => {
      signOut();
      setIsAuth(false);
      setAuthUser(null);
      setCurrentUser(null);
      localStorage.removeItem(SESSION_DATE_KEY);
      localStorage.removeItem(TOKEN_KEY);
    },
  };

  useEffect(() => {
    const onGetCurrentUser = async () => {
      try {
        const { data } = await fetchMe();
        setCurrentUser(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (authUser) {
      onGetCurrentUser();
    }
  }, [authUser]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
