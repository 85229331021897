import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Radio } from "antd";
import styled from "styled-components";

import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { DashboardCard } from "../../../components/dashboard-layout/styledComponents";
import { columnsUsers } from "./tableConfig";
import Button from "../../../components/dashboard-layout/button/Button";
import { fetchPartnerBranchUsers } from "../../../services/partners.service";

const DashboardTableBranchUsers = styled(DashboardTable)`
  &.table-users-branch {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export default function BranchUsers({ modalSetting }) {
  const { id } = useParams();
  const [branchUsers, setBranchUsers] = useState([]);
  const [invited, setInvited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarActiveVal, setSearchBarActiveVal] = useState("");
  const resetPagination = () => {
    setPagination({ page: 1, pageSize: 10 });
  };
  const onGetBranchUsers = async () => {
    setLoading(true);
    try {
      const result = await fetchPartnerBranchUsers({
        page: pagination.page,
        itemsPerPage: pagination.pageSize,
        partnerBranchId: parseInt(id),
        invited: invited,
      });
      const formatedRows = result.result.users.map((row) => {
        const formatData = {
          id: row?.id,
          name: `${row?.firstName || "-"} ${row?.lastName || ""}`,
          type:
            row?.type === "PARTNER_BRANCH_ADMIN"
              ? "Administrador sucursal"
              : "Administrador Aliado",
          email: row?.email?.toLowerCase() || "-",
        };
        return formatData;
      });
      setBranchUsers(formatedRows);
      setPagination((pagination) => ({
        ...pagination,
        page: result.result.pagination.currentPage,
        pageSize: result.result.pagination.itemsPerPage,
        total: result.result.pagination.totalItems,
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeSearchBar = (e) => {
    setSearchBarActiveVal(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetBranchUsers(), 400);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarActiveVal, pagination.page, invited]);

  const onChangePage = (val) => {
    setPagination((pagination) => ({ ...pagination, page: val }));
  };
  return (
    <DashboardCard direction="column">
      <Radio.Group defaultValue="ALL" buttonStyle="solid">
        <Radio.Button
          value="ALL"
          onClick={() => {
            setInvited(false);
            resetPagination();
          }}
        >
          Usuarios
        </Radio.Button>
        <Radio.Button
          value="INVITED"
          onClick={() => {
            setInvited(true);
            resetPagination();
          }}
        >
          Usuarios invitados
        </Radio.Button>
      </Radio.Group>
      <DashboardTableBranchUsers
        className="table-users-branch"
        columns={columnsUsers}
        tableTitle="Usuarios"
        data={branchUsers}
        pagination={pagination}
        page={pagination.page}
        rowKey="key"
        loading={loading}
        searchBarPlaceholder="Buscar usuarios"
        onChangeSearchBar={onChangeSearchBar}
        onChangePage={onChangePage}
        customButton={
          <>
            <Button type="primary" onClick={modalSetting.showModal}>
              + Invitar usuario
            </Button>
          </>
        }
      />
    </DashboardCard>
  );
}
