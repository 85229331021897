import React, { useState } from "react";
import AuthInput from "../../../components/auth-layout/forms/input";
import { MdMarkunread } from "react-icons/md";
import { validateEmail } from "../../../utils/validations";
import {
  FormAuthStyle,
  AuthSubmitBtn,
  AuthCard,
  ScreenTitle,
  MainTitle,
  AuthText,
} from "../../../components/auth-layout/styledComponents";

export default function ForgetPassword() {
  const [formState, updateForm] = useState({
    formValid: false,
    emailValid: false,
    emailError: "",
    loading: false,
    error: false,
    errorMessage: "",
    email: "",
  });
  const handleEmailChange = (e) => {
    e.preventDefault();
    const email = e.target.value;
    const validEmail = validateEmail(email);
    const errorMessage = validEmail
      ? ""
      : "Inserte un Correo electrónico válido";
    const newState = {
      ...formState,
      emailValid: validEmail,
      email: email,
      emailError: errorMessage,
    };
    const validForm = isValidForm(newState);
    updateForm({
      ...newState,
      formValid: validForm,
    });
  };
  const actionForm = (e) => {
    e.preventDefault();
    if (formState.formValid) {
      // console.log("Send form");
    }
  };
  const isValidForm = ({ emailValid }) => emailValid;
  return (
    <AuthCard>
      <ScreenTitle>Iniciar Sesión</ScreenTitle>
      <MainTitle>¿Olvidaste tu contraseña?</MainTitle>
      <AuthText alignText="center">
        Para restablecer tu contraseña ingresa tu email asociado y te enviaremos
        un correo electrónico.
      </AuthText>
      <FormAuthStyle onSubmit={actionForm}>
        <AuthInput
          inputType="email"
          inputName="email"
          inputLabel="Correo electrónico"
          error={!formState.emailValid}
          errorMessage={formState.emailError}
          icon={<MdMarkunread />}
          callbackChange={handleEmailChange}
          dataValue={formState.email}
        />
        <AuthSubmitBtn
          formInvalid={!formState.formValid}
          disabled={!formState.formValid}
        >
          Continuar
        </AuthSubmitBtn>
      </FormAuthStyle>
    </AuthCard>
  );
}
