import styled from "styled-components";

export const ToolbarLayout = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: space-between;
`;
export const DropdownLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & span {
    font-size: 0.8em;
    color: #76889a;
  }
`;
