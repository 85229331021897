import React from "react";
import { OrdersContainer } from "./styledComponents";
import { DashboardCardTitle } from "../../../../components/dashboard-layout/styledComponents";
import Spinner from "../../../../components/dashboard-SA/Spinner";
import { LabelText } from "../../../dashboard-sa/Clients/NewClient";
import OrderDriver from "./OrderDriver";
import { ProfileContainer } from "../styledComponents";

export default function Orders({ loading, orderServices }) {
  return (
    <OrdersContainer>
      {loading ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
            overflowY: "auto",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <ProfileContainer>
          <div>
            <DashboardCardTitle>Órdenes</DashboardCardTitle>
          </div>
          {orderServices.length > 0 ? (
            orderServices.map((item, i) => <OrderDriver {...item} key={i} />)
          ) : (
            <LabelText style={{ textAlign: "center", marginTop: 20 }}>
              No hay órdenes disponibles
            </LabelText>
          )}
        </ProfileContainer>
      )}
    </OrdersContainer>
  );
}
