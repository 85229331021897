import { axiosInstance } from "../utils/axios";

export const fetchExternalEntities = async (page, itemsPerPage) => {
  return axiosInstance.get("/externals/entities", {
    params: { page, itemsPerPage },
  });
};

export const fetchPaymentExternalEntities = async (
  page,
  itemsPerPage,
  externalEntityId
) => {
  return axiosInstance.get("/externals/payment/entities", {
    params: { page, itemsPerPage, externalEntityId },
  });
};
