import React, { useState, useEffect } from "react";
import { columns } from "./transactionTable";
import { useParams } from "react-router-dom";
import moment from "moment";

import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { getWalletMovements } from "../../../services/wallets.service";

export default function ClientTransactions({ dateRange }) {
  const params = useParams();
  const [clientTransactions, setClientTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarActiveVal, setSearchBarActiveVal] = useState("");
  const onChangeSearchBar = (e) => {
    setSearchBarActiveVal(e.target.value);
  };
  const onGetClientTransactions = async (pag) => {
    setLoading(true);
    try {
      const { data } = await getWalletMovements({
        page: pag.page,
        itemsPerPage: pag.pageSize,
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
        ownerId: params.id,
        ownerType: "ORGANIZATION",
      });
      const responseFormatted = data.result.data.map((elm) => ({
        id: elm?.TransactionId || "-",
        manifestNumber: elm?.manifestNumber || "-",
        driverName:
          `${elm?.destination?.firstName} ${elm.destination.lastName}` || "-",
        date: moment(elm?.createdAt).format("MMM DD, YYYY  hh:mm a") || "-",
        mount: `$ ${new Intl.NumberFormat("de-DE").format(elm?.value * -1)}`,
        driverPhoto: elm?.urlProfileImage,
      }));
      setClientTransactions(responseFormatted);
      setPagination((pagination) => ({
        ...pagination,
        page: data.result.pagination.currentPage,
        total: data.result.pagination.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetClientTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarActiveVal, pagination.page, pagination.pageSize, dateRange]);
  const onChangePage = (val) => {
    setPagination((pagination) => ({ ...pagination, page: val }));
  };
  return (
    <>
      <DashboardTable
        columns={columns}
        tableTitle={`Total de Transacciones: ${pagination.total}`}
        data={clientTransactions}
        customButton={null}
        pagination={pagination}
        page={pagination.page}
        rowKey="key"
        loading={loading}
        onChangeSearchBar={onChangeSearchBar}
        onChangePage={onChangePage}
      />
    </>
  );
}
