import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Radio } from "antd";

import Button from "../../../components/dashboard-layout/button/Button";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./tableConfig";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import CreateUsers from "./CreateUsers";
import {
  fetchUserInvitations,
  fetchUsersAdmin,
} from "../../../services/users.service";

const setUserRole = (role) => {
  switch (role) {
    case "SUPER_ADMIN":
      return "Super administrador";
    default:
      return "-";
  }
};

export default function UsersSuperAdmin() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pageSize: 10,
  });
  // eslint-disable-next-line no-unused-vars
  const [searchBarActiveVal, setSearchBarActiveVal] = useState("");
  const [invited, setInvited] = useState(false);

  const history = useHistory();
  const modal = useDashboardModal();
  const onChangeSearchBar = (e) => {
    setSearchBarActiveVal(e.target.value);
  };
  const resetPagination = () => {
    setPagination((pagination) => ({ ...pagination, page: 1, pageSize: 10 }));
  };
  const onGetUsers = useCallback(
    async (pag) => {
      setLoading(true);
      try {
        const { data } = !invited
          ? await fetchUsersAdmin(pag.page, pag.pageSize)
          : await fetchUserInvitations(pag.page, pag.pageSize, "SUPER_ADMIN");
        const users = !invited ? data?.users : data?.invitations;
        const formatedRows = users?.map((row) => {
          const formatData = {
            id: row.id,
            name: `${row.firstName || "-"} ${row.lastName || ""}`,
            email: row.email || "-",
            userType: setUserRole(row.type),
          };
          return formatData;
        });
        setUsers(formatedRows);
        setPagination((pagination) => ({
          ...pagination,
          page: data.pagination.currentPage,
          total: data.pagination.total,
        }));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [invited]
  );

  const handleRowClick = (e, r) => {
    e.stopPropagation();
    history.push(`/users/${r.id}`);
  };

  const onChangePage = (page) => {
    setPagination((pagination) => ({ ...pagination, page }));
  };
  const onChangeItemsPerPage = (index, pageSize) => {
    setPagination((pagination) => ({ ...pagination, pageSize: pageSize }));
  };

  useEffect(() => {
    let timeout = setTimeout(() => onGetUsers(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize, invited]);

  return (
    <>
      <DashboardSectionTitle>Usuarios</DashboardSectionTitle>
      <Radio.Group defaultValue="ALL" buttonStyle="solid">
        <Radio.Button
          value="ALL"
          onClick={() => {
            setInvited(false);
            resetPagination();
          }}
        >
          Usuarios
        </Radio.Button>
        <Radio.Button
          value="INVITED"
          onClick={() => {
            setInvited(true);
            resetPagination();
          }}
        >
          Usuarios invitados
        </Radio.Button>
      </Radio.Group>
      <DashboardTable
        columns={columns}
        tableTitle="Lista de usuarios"
        data={users}
        pagination={pagination}
        page={pagination.page}
        rowKey="key"
        loading={loading}
        onClickRow={handleRowClick}
        searchBarPlaceholder="Buscar usuarios"
        onChangeSearchBar={onChangeSearchBar}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        customButton={
          <Button type="primary" actionButton={modal.showModal}>
            + Invitar usuario
          </Button>
        }
      />
      <CreateUsers
        onUpdateUsers={() => onGetUsers(pagination)}
        modalSetting={{ ...modal, title: "Agregar un usuario" }}
      />
    </>
  );
}
