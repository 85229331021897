import React, { useContext } from "react";
import { Divider, Timeline, Spin } from "antd";

import {
  DashboardSectionTitle,
  HistoryItem,
} from "../../../components/dashboard-layout/styledComponents";
import { Context } from "../../../Context";
import ServiceOrderHistoryItem from "../../../components/ServiceOrderHistoryItem";

export default function ServiceOrderHistory({
  log,
  loadingLog,
  antIconLoading,
  serviceOrder,
}) {
  const { currentUser } = useContext(Context);

  return (
    <>
      <Divider />
      <DashboardSectionTitle style={{ fontSize: 16 }}>
        Historial
      </DashboardSectionTitle>
      {loadingLog ? (
        <Spin indicator={antIconLoading} />
      ) : (
        <HistoryItem>
          <Timeline>
            {log.length > 0 &&
              log.reverse().map((item, idx) => {
                return (
                  <ServiceOrderHistoryItem
                    key={idx}
                    index={idx}
                    serviceOrder={serviceOrder}
                    currentUser={currentUser}
                    item={item}
                  />
                );
              })}
          </Timeline>
        </HistoryItem>
      )}
    </>
  );
}
