import React, { useState } from "react";
import AuthInput from "../../../components/auth-layout/forms/input";
import { MdVisibilityOff, MdVisibility, MdCheck } from "react-icons/md";
import { PassWordListRequirement } from "../../../components/auth-layout/styledComponents";

export default function PasswordConfirm({
  validUppercase = false,
  validMinlength = false,
  validMatch = false,
  handleChange,
  handlePasswordConfirmChange,
  dataValuePassword = "",
  dataValuePasswordConfirm = "",
}) {
  const [visibility, setVisibility] = useState({
    visibilityPassword: false,
    visibilityConfirm: false,
  });

  const iconVisibilityPassword = visibility.visibilityPassword ? (
    <MdVisibility />
  ) : (
    <MdVisibilityOff />
  );
  const iconVisibilityConfirm = visibility.visibilityConfirm ? (
    <MdVisibility />
  ) : (
    <MdVisibilityOff />
  );
  return (
    <React.Fragment>
      <AuthInput
        inputType={visibility.visibilityPassword ? "text" : "password"}
        inputName="password"
        inputLabel="Contraseña"
        icon={iconVisibilityPassword}
        callbackChange={handleChange}
        dataValue={dataValuePassword}
        onclickIcon={(e) =>
          setVisibility({
            ...visibility,
            visibilityPassword: !visibility.visibilityPassword,
          })
        }
        iconState={visibility.visibilityPassword}
      />
      <AuthInput
        inputType={visibility.visibilityConfirm ? "text" : "password"}
        inputName="confirm-password"
        inputLabel="Confirmar Contraseña"
        icon={iconVisibilityConfirm}
        dataValue={dataValuePasswordConfirm}
        callbackChange={handlePasswordConfirmChange}
        onclickIcon={(e) =>
          setVisibility({
            ...visibility,
            visibilityConfirm: !visibility.visibilityConfirm,
          })
        }
        iconState={visibility.visibilityConfirm}
      />
      <PassWordListRequirement
        validateUpperCase={validUppercase}
        validateMinLength={validMinlength}
        validMatch={validMatch}
      >
        La contraseña debe contener:
        <li className="minlength">
          <MdCheck />
          Un mínimo de 8 caracteres
        </li>
        <li className="uppercase">
          <MdCheck />
          Al menos una letra mayúscula.
        </li>
        <li className="confirmation">
          <MdCheck />
          Las contraseñas deben coincidir.
        </li>
      </PassWordListRequirement>
    </React.Fragment>
  );
}
