import React from "react";
import { Row, Col } from "antd";
import {
  DashboardCard,
  DashboardCardTitle,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import {
  StatusItem,
  ProfileImage,
  ProfileBranchLayout,
} from "./styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import GoogleMapReact from "google-map-react";
import { MdLocationOn, MdModeEdit } from "react-icons/md";
import ImgPlaceholder from "../../../assets/img/img-placeholder.svg";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { Link, useParams } from "react-router-dom";

const REACT_APP_GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const isBranchActive = (status) => {
  switch (status) {
    case "ACTIVE":
      return <StatusItem status="success">Activa</StatusItem>;
    case "INACTIVE":
      return <StatusItem status="warning">Inactiva</StatusItem>;
    default:
      return <StatusItem status="success">Activa</StatusItem>;
  }
};

export default function BranchProfile({ branchData, loading }) {
  const { id, partnerId } = useParams();
  return (
    <ProfileBranchLayout>
      <Row>
        <Col span={8}>
          <DashboardCard>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <DashboardCardTitle>
                  Información de la sucursal
                </DashboardCardTitle>
                <FlexContainerHelper justify="space-between" align="center">
                  <ProfileImage src={ImgPlaceholder} />
                  <strong>
                    <span>{isBranchActive()}</span>
                  </strong>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between" align="center">
                  <span>Nombre</span>
                  <strong>
                    <span>{branchData?.name || "-"}</span>
                  </strong>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between" align="center">
                  <span>Correo electrónico</span>
                  <strong>
                    <span>{branchData?.email || "-"}</span>
                  </strong>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between" align="center">
                  <span>Dirección</span>
                  <strong>
                    <span>{branchData?.address || "-"}</span>
                  </strong>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between" align="center">
                  <span>Teléfono</span>
                  <strong>
                    <span>{branchData?.phoneNumber || "-"}</span>
                  </strong>
                </FlexContainerHelper>
                <Button type="secondary" className="edit-branch-profile">
                  <MdModeEdit />
                  <Link
                    to={`/ally/${partnerId}/branches/edit/${id}`}
                    style={{ color: "inherit" }}
                  >
                    <span>Editar sucursal</span>
                  </Link>
                </Button>
              </div>
            )}
          </DashboardCard>
        </Col>
        <Col span={16}>
          <DashboardCard direction="column" className="location-card-container">
            <DashboardCardTitle>Ubicación</DashboardCardTitle>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div style={{ height: "205px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: REACT_APP_GOOGLE_MAP_KEY,
                  }}
                  center={{
                    lat: branchData?.latitude,
                    lng: branchData?.longitude,
                  }}
                  defaultZoom={15}
                  yesIWantToUseGoogleMapApiInternals
                >
                  <MdLocationOn
                    size={38}
                    color={"#1CA892"}
                    lat={branchData?.latitude}
                    lng={branchData?.longitude}
                  />
                </GoogleMapReact>
              </div>
            )}
          </DashboardCard>
        </Col>
      </Row>
    </ProfileBranchLayout>
  );
}
