import { axiosInstance } from "../utils/axios";

export const fetchCommissions = async (params) => {
  return axiosInstance.get("/commissions", {
    params,
  });
};

export const fetchCommissionTotal = async (params) => {
  return axiosInstance.get("/commissions/total", {
    params,
  });
};
