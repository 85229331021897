import React from "react";
import { MainSection, BackgroundLayer } from "./styledComponents";
import LogoImage from "../../assets/img/saLogo.png";
import Logo from "./logo/logo";
import Footer from "./footer/footer";

export default function AuthLayout({ children }) {
  return (
    <React.Fragment>
      <MainSection>
        <BackgroundLayer />
        <Logo imageSource={LogoImage} altText={"Hoy +"} />
        {children}
        <Footer />
      </MainSection>
    </React.Fragment>
  );
}
