import { axiosInstance } from "../utils/axios";

export const fetchWallet = async (data) => {
  return axiosInstance.get("/wallets", {
    params: data,
  });
};

export const getWalletMovement = async (id) => {
  return axiosInstance.get(`/wallets/movement/${id}`);
};

export const getWalletMovements = async (data) => {
  return axiosInstance.get("/wallets/movements", {
    params: data,
  });
};
