import React, { useState } from "react";
import PasswordConfirm from "../../../components/auth-layout/forms/PasswordConfirm";
import {
  validateMinLength,
  validateUppercase,
  matchStringValidate,
} from "../../../utils/validations";
import {
  FormAuthStyle,
  AuthSubmitBtn,
  AuthCard,
  ScreenTitle,
  MainTitle,
  AuthText,
} from "../../../components/auth-layout/styledComponents";

export default function ResetPassword() {
  const [formState, updateForm] = useState({
    formValid: false,
    passwordConfirmed: false,
    loading: false,
    error: false,
    passwordUppercaseValid: false,
    passwordMinlengthValid: false,
    password: "",
    passwordConfirm: "",
  });

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const passwordVal = e.target.value;
    const validPasswordUppercase = validateUppercase(passwordVal);
    const validPasswordMinlength = validateMinLength(passwordVal, 8);
    const passwordMatch = matchStringValidate(
      e.target.value,
      formState.passwordConfirm
    );
    const newState = {
      ...formState,
      password: passwordVal,
      passwordUppercaseValid: validPasswordUppercase,
      passwordMinlengthValid: validPasswordMinlength,
      passwordConfirmed: passwordMatch,
    };
    const validForm = isValidForm(newState);
    updateForm({
      ...newState,
      formValid: validForm,
    });
  };

  const handlePasswordConfirmChange = (e) => {
    e.preventDefault();
    const passwordVal = e.target.value;
    const passwordMatch = matchStringValidate(
      e.target.value,
      formState.password
    );
    const newState = {
      ...formState,
      passwordConfirm: passwordVal,
      passwordConfirmed: passwordMatch,
    };
    const validForm = isValidForm(newState);
    updateForm({
      ...newState,
      formValid: validForm,
    });
  };

  const actionForm = (e) => {
    e.preventDefault();
    if (formState.formValid) {
      console.log("Submit");
    }
  };

  const isValidForm = ({
    passwordMinlengthValid,
    passwordUppercaseValid,
    passwordConfirmed,
  }) => passwordMinlengthValid && passwordUppercaseValid && passwordConfirmed;

  return (
    <AuthCard>
      <ScreenTitle>Registro</ScreenTitle>
      <MainTitle>Ingresa tu nueva contraseña</MainTitle>
      <AuthText>Por favor ingresa una nueva contraseña</AuthText>
      <FormAuthStyle onSubmit={actionForm}>
        <PasswordConfirm
          handleChange={handlePasswordChange}
          validUppercase={formState.passwordUppercaseValid}
          validMinlength={formState.passwordMinlengthValid}
          dataValuePassword={formState.password}
          dataValuePasswordConfirm={formState.passwordConfirm}
          handlePasswordConfirmChange={handlePasswordConfirmChange}
          validMatch={formState.passwordConfirmed}
        />
        <AuthSubmitBtn
          formInvalid={!formState.formValid}
          disabled={!formState.formValid}
        >
          Restablecer contraseña
        </AuthSubmitBtn>
      </FormAuthStyle>
    </AuthCard>
  );
}
