import React from "react";
import {
  DashboardCard,
  DashboardCardTitle,
  Indicator,
  IconCardWrapper,
} from "../dashboard-layout/styledComponents";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import styled from "styled-components";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Spinner from "../dashboard-SA/Spinner";

const DashboardItem = ({
  title,
  value,
  since = "desde el último mes",
  factor,
  icon,
  iconColor,
  iconBg,
  factorStatus,
  style,
  link,
  loading = false,
  minheight = "143px",
}) => (
  <DashboardCard minheight={minheight} style={style}>
    {!loading ? (
      <>
        <section>
          <DashboardCardTitle>{title}</DashboardCardTitle>
          <h2>{value}</h2>
          {factor !== "" && (
            <>
              {factorStatus === "up" ? (
                <Indicator color="#00BE6D">
                  <FaLongArrowAltUp />
                  {factor}
                </Indicator>
              ) : (
                <Indicator color="#E4032B">
                  <FaLongArrowAltDown />
                  {factor}
                </Indicator>
              )}

              <span>{since}</span>
            </>
          )}
          {link && (
            <LinkContainer color={link.color}>
              <span>
                <Link to={link.url}>{link.text}</Link>
              </span>
              <BsArrowRight
                color={link.color}
                size={20}
                style={{ marginLeft: 5 }}
              />
            </LinkContainer>
          )}
        </section>
        <aside>
          <IconCardWrapper color={iconColor} background={iconBg}>
            {icon}
          </IconCardWrapper>
        </aside>
      </>
    ) : (
      <div
        style={{
          width: "100%",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 143,
        }}
      >
        <Spinner />
      </div>
    )}
  </DashboardCard>
);

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.color || "inherit"};
  & a {
    color: inherit;
  }
`;

export default DashboardItem;
