import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MdMarkunread, MdVisibility, MdVisibilityOff } from "react-icons/md";

import AuthInput from "../../../components/auth-layout/forms/input";
import {
  FormAuthStyle,
  AuthSubmitBtn,
} from "../../../components/auth-layout/styledComponents";
// import { Link } from "react-router-dom";
import useInput from "../../../hooks/useInput";
import {
  signInWithEmailAndPassword,
  validateUserInvited,
} from "../../../services/firebase";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { SESSION_DATE_KEY } from "../../../utils/constants";
import moment from "moment";

export default function LoginForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = useInput("", isSubmitting);
  const password = useInput("", isSubmitting);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const onLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!email.value || !password.value) {
      return;
    }
    if (error) {
      setError(null);
    }
    setLoading(true);
    try {
      const { data } = await validateUserInvited(email.value);
      if (data.blocked) {
        throw Error("Usuario bloqueado");
      }
      await signInWithEmailAndPassword(email.value, password.value);

      localStorage.setItem(SESSION_DATE_KEY, moment().toISOString());
      setLoading(false);
      props.history.push("/");
    } catch (error) {
      if (error.code !== 41) {
        if (error?.code?.includes("auth/user-not-found")) {
          email.setError("No existe este usuario");
        }
        if (error?.code?.includes("auth/wrong-password")) {
          password.setError("Contraseña incorrecta");
        }
      } else {
        setError(error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    if (params.get("email")) {
      const emailParam = params.get("email");
      email.onSetValue(emailParam);
    }
  }, [history, email]);

  return (
    <FormAuthStyle onSubmit={onLogin}>
      <AuthInput
        inputType="email"
        inputNmae="email"
        inputLabel="Correo electrónico"
        dataValue={email.value}
        error={email.error}
        errorMessage={email.error}
        callbackChange={email.onChange}
        icon={<MdMarkunread />}
      />
      <AuthInput
        inputType={showPassword ? "text" : "password"}
        inputNmae="password"
        inputLabel="Contraseña "
        error={password.error}
        errorMessage={password.error}
        dataValue={password.value}
        callbackChange={password.onChange}
        icon={
          showPassword ? (
            <MdVisibility
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <MdVisibilityOff
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            />
          )
        }
      />
      <ErrorMessage error={error}>{error?.message || ""}</ErrorMessage>
      {!loading ? (
        <AuthSubmitBtn>Continuar</AuthSubmitBtn>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", padding: 18 }}>
          <Spinner />
        </div>
      )}
    </FormAuthStyle>
  );
}
