import { useState } from "react";

export function useDashboardModal() {
  const [visible, setVisibility] = useState(false);

  const showModal = () => {
    setVisibility(true);
  };

  const handleOk = (e) => {
    setVisibility(false);
  };

  const handleCancel = (e) => {
    setVisibility(false);
  };

  return { visible, showModal, handleOk, handleCancel };
}
