import styled from "styled-components";

export const ProfileContainer = styled.div`
  padding-right: 25px;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  position: relative;
  left: 20px;
  & div {
    margin-top: 20px;
  }
  & div:first-child {
    margin-top: 0;
  }
  & div div {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const ProfileTitle = styled.h3`
  color: #051a55;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
`;

export const CardBlue = styled.div`
  background-color: #f6f9fe;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 10px;

  & .main-icon {
    font-size: 24px;
    color: #1645b8;
  }
`;
