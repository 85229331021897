import React from "react";
import { LabelText } from "../../containers/dashboard-sa/Clients/NewClient";
import OrderItem from "./OrderItem";
import Spinner from "./Spinner";
import styled from "styled-components";
import { Spin } from "antd";

const ServiceOrderListLayout = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  & .so-list-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 437px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ProfileServiceOrder = ({
  items,
  title,
  loading,
  containerRef,
  scrollLoading,
  handleScroll,
}) => {
  return (
    <ServiceOrderListLayout>
      <LabelText style={{ marginBottom: 26 }}>{title}</LabelText>
      {items && !loading && items.length > 0 && (
        <div
          className="so-list-container"
          ref={containerRef}
          onScroll={handleScroll}
        >
          {items.map((elm, index) => (
            <OrderItem key={index} data={elm} />
          ))}
          {scrollLoading && (
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      )}

      {items && items.length === 0 && !loading && (
        <div>No hay órdenes de servicio.</div>
      )}

      {loading ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
          }}
        >
          <Spinner />
        </div>
      ) : null}
    </ServiceOrderListLayout>
  );
};

export default ProfileServiceOrder;
