import React from "react";
import { Spin } from "antd";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

function ServiceModal({ modalSetting }) {
  return (
    <DashboardModal {...modalSetting} footer={null}>
      <p>¿Estas seguro que desea generar el pago restante?</p>
      <div>
        <FlexContainerHelper justify="flex-end">
          <Button type="alternative" actionButton={modalSetting.handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            actionButton={async () => {
              await modalSetting.onPress();
              await modalSetting.handleCancel();
            }}
            disabled={modalSetting.loadingAdvance}
          >
            {modalSetting.loadingAdvance ? (
              <Spin indicator={antIcon} />
            ) : (
              "Confirmar"
            )}
          </Button>
        </FlexContainerHelper>
      </div>
    </DashboardModal>
  );
}

export default ServiceModal;
