import React, { useState, useCallback } from "react";
import Button from "../../../components/dashboard-layout/button/Button";
import { useForm, Controller } from "react-hook-form";
import { Row } from "antd";
import {
  InputLayout,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { toast } from "react-toastify";
import styled from "styled-components";
import { inviteUser } from "../../../services/invitations.service";

const LabelText = styled.label`
  color: #0a181d;
  font-size: 14px;
  font-weight: 700;
  display: block;
`;

export default function UserForm({ modalSetting, onUpdateUsers }) {
  const { control, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        await inviteUser({ email: data.email, type: "PARTNER_ADMIN" });
        onUpdateUsers();
        toast.success("Se ha invitado el usuario satisfactoriamente");
        setValue("email", "");
        modalSetting.handleCancel();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalSetting, onUpdateUsers]
  );

  return (
    <DashboardModal {...modalSetting} footer={null}>
      <p>
        Para agregar un usuario ingresa el correo electrónico, se le enviara una
        invitación para que acceda a la aplicación.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginBottom: "50px" }}>
          <LabelText htmlFor="email">Correo Electrónico</LabelText>
          <Controller
            as={InputLayout}
            name="email"
            control={control}
            defaultValue=""
            placeholder="Ingresar correo electrónico"
            type="email"
            size="large"
            rules={{
              required: "Este campo es requerido.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Dirección de correo electrónico inválido",
              },
              maxLength: {
                value: 100,
                message: "Máximo 100 caracteres permitidos",
              },
            }}
          />
          <ErrorMessage error={errors.email ? 1 : 0}>
            {errors?.email?.message}
          </ErrorMessage>
        </Row>
        <div>
          <FlexContainerHelper justify="flex-end">
            <Button type="alternative" actionButton={modalSetting.handleCancel}>
              Cancel
            </Button>
            <Button type="submit">
              {loading ? <Spinner color="#ffF" /> : "Enviar invitación"}
            </Button>
          </FlexContainerHelper>
        </div>
      </form>
    </DashboardModal>
  );
}
