import { axiosInstance } from "../utils/axios";

export const fetchUsersAdmin = async (page, itemsPerPage) => {
  return axiosInstance
    .get("/users/admin", {
      params: {
        page,
        itemsPerPage,
      },
    })
    .then((res) => ({
      data: res.data.result,
    }));
};

export const fetchUsers = async (
  page,
  itemsPerPage,
  type = "PARTNER_ADMIN"
) => {
  return axiosInstance
    .get("/users", {
      params: {
        page,
        itemsPerPage,
        type,
      },
    })
    .then((res) => ({ data: res.data.result }));
};

export const fetchUserInfo = async () => {
  return axiosInstance.get("/users/info").then((res) => res.data);
};

export const fetchUserInvitations = async (page, itemsPerPage, type) => {
  return axiosInstance
    .get("/users/invitations", {
      params: {
        page,
        itemsPerPage,
        type,
      },
    })
    .then((res) => ({
      data: res.data.result,
    }));
};

export const updateUser = async (id, data) => {
  return axiosInstance.put(`/users/${id}`, data).then((res) => res.data);
};
