import React, { useState, useEffect } from "react";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./tableConfig";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { useHistory } from "react-router-dom";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { Row, Col, DatePicker } from "antd";
import { MdAttachMoney } from "react-icons/md";
import moment from "moment";
import { fetchPaymentIntents } from "../../../services/payments.service";
import { fetchExternalEntities } from "../../../services/external.service";
import { fetchWallet } from "../../../services/wallets.service";
import usePagination from "../../../hooks/usePagination";

const { RangePicker } = DatePicker;

export default function EfectyList() {
  const [transactions, setTransactions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filter, setFilter] = useState("");
  const [walletBalance, setWalletBalance] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();
  const history = useHistory();

  const onChangeFilter = (e) => {
    setFilter(e.target.value);
  };

  const onGetWalletBalance = async () => {
    setLoadingBalance(true);
    try {
      const responseEntities = await fetchExternalEntities(1, 10);
      const efectyType = responseEntities.data.data.filter(
        (elm) => elm.type === "EFECTY"
      )[0];
      const response = await fetchWallet({
        ownerId: efectyType?.id || 1,
        ownerType: "EXTERNAL_ENTITYS",
      });
      setWalletBalance(response?.data?.result?.availableBalance);
      setLoadingBalance(false);
    } catch (error) {
      setLoadingBalance(false);
    }
  };

  const onGetEfectyTransactions = async () => {
    setLoading(true);
    try {
      const response = await fetchPaymentIntents({
        page: page,
        itemsPerPage: itemsPerPage,
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
        type: "EFECTY",
      });
      const formatedData = response.data.map((elm) => ({
        id: elm.id,
        createdAt: elm.createdAt || "-",
        paidAt: elm?.paidAt || "-",
        status: elm?.status,
        user: elm?.user,
        idNumber: elm?.destination?.idNumber || "-",
        value: elm?.value || "-",
      }));
      setTransactions(formatedData);
      setPagination(response.pagination);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetWalletBalance();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(onGetEfectyTransactions, 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, filter, dateRange]);

  return (
    <>
      <Row>
        <Col span={8}>
          <DashboardSectionTitle>
            Solicitudes de Retiro Efecty
          </DashboardSectionTitle>
        </Col>
        <Col span={16}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
          <Row justify="end">
            <Col span={10}>
              <DashboardItem
                title="Saldo restante en efecty"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  walletBalance
                )}`}
                factor=""
                icon={<MdAttachMoney />}
                iconColor="#1CA892"
                iconBg="#1CA89212"
                factorStatus="up"
                link={{
                  url: "/efecty/payments",
                  color: "#1CA892",
                  text: "Ver pagos",
                }}
                loading={loadingBalance}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <DashboardTable
        columns={columns}
        data={transactions}
        pagination={pagination}
        page={page}
        loading={loading}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        tableTitle="Lista de solicitudes"
        searchBarPlaceholder="Buscar solicitud de retiro"
        onChangeSearchBar={onChangeFilter}
        onClickRow={(text, record) => {
          history.push(`/efecty/${record.id}`);
        }}
      />
    </>
  );
}
