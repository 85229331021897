import React from "react";
import { ButtonLayout } from "./styledComponents";

export default function Button({
  type = "primary",
  icon,
  children,
  actionButton,
  disabled,
  ...props
}) {
  return (
    <ButtonLayout
      color={type}
      onClick={actionButton || null}
      disabled={disabled}
      {...props}
    >
      {icon ? icon : ""}
      {children}
    </ButtonLayout>
  );
}
