import React from "react";
import { MdModeEdit, MdBlock } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { ROUTES } from "../../../utils/constants";

export const StatusItem = styled.div`
  height: 30px;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${(props) => renderStatus(props.status)}
`;
const renderStatus = (status) => {
  switch (status) {
    case "ENABLED": {
      return `
      background: #00BE6D1A; 
        color: #00BE6D;
        `;
    }
    case "DISABLED": {
      return `
        
        background: #E4032B1A; 
          color: #E4032B;
      
      `;
    }
    default: {
      return ` background: #00BE6D1A; 
      color: #00BE6D;`;
    }
  }
};
export const alliesColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => (
      <Link to="/ally/dsafds">
        <span style={{ color: "#000" }}>{text}</span>
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "NIT",
    dataIndex: "nit",
    key: "nit",
    render: (text) => <span style={{ color: "#585858" }}>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Teléfono",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sortDirections: ["descend"],
  },
  {
    title: "Correo Electrónico",
    dataIndex: "email",
    key: "email",
    render: (text) => <span style={{ color: "#585858" }}>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <StatusItem status={text}>
        {text === "ENABLED" ? "Activo" : "Bloqueado"}
      </StatusItem>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    dataIndex: "id",
    key: "id",
    render: (id) => {
      return <ActionsButtons id={id} />;
    },
  },
];
export function useSelectableAlliesRow() {
  //const [selectedRowKeys, setSelectedRowkeys] = useState([]);
  /*const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowkeys({ selectedRowKeys });
  };*/

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({}),
  };

  return rowSelection;
}

export const alliesData = [
  {
    id: "1",
    name: "Terpel Bogota",
    phoneNumber: "+57 322 123 4567",
    nit: "1234567",
    email: "soporte@expresslogistica.com",
    address: "Cl. 46a #85 A – 51, Bogotá Colombia.",
  },
  {
    id: "2",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "3",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "4",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "5",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "6",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "7",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "8",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "9",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: "10",
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
  {
    id: 11,
    name: "Terpel Bogota",
    nit: "1234567",
    phoneNumber: "+57 322 123 4567",
    email: "soporte@expresslogistica.com",
    type: "Transportador propio",
  },
];

const ActionsButtons = ({ id }) => {
  const history = useHistory();
  return (
    <FlexContainerHelper justify="flex-start" className="icons">
      <MdModeEdit
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          history.push(ROUTES.editAllies({ id }));
        }}
        size={18}
        style={{ marginRight: 15 }}
      />
      <MdBlock size={18} />
    </FlexContainerHelper>
  );
};
