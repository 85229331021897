import React from "react";
import LoginForm from "./LoginForm";
import {
  AuthCard,
  ScreenTitle,
  MainTitle,
  AuthText,
  CardLink,
} from "../../../components/auth-layout/styledComponents";
import { BsArrowRight } from "react-icons/bs";
import { Row } from "antd";
import styled from "styled-components";

export const RowLogin = styled(Row)`
  width: 100%;
  padding-top: 10px;
  align-items: center;
`;

export const CardLinkLogin = styled(CardLink)`
  font-size: 10px;
`;

export const AuthTextLogin = styled(AuthText)`
  margin: 0;
  margin-right: 3px;
`;

export default function login({ history }) {
  return (
    <AuthCard>
      <ScreenTitle>Iniciar Sesión</ScreenTitle>
      <MainTitle>Bienvenido a HOY+</MainTitle>
      <AuthText>
        Por favor ingresa tu Correo electrónico y contraseña para iniciar
        sesión.
      </AuthText>
      <LoginForm history={history} />
      <RowLogin gutter={{ xs: 4 }} justify="end" align="center">
        <AuthTextLogin>¿No tienes cuenta? </AuthTextLogin>
        <CardLinkLogin to="/register"> Regístrate</CardLinkLogin>
        <BsArrowRight color="#1645b8" size={20} style={{ marginLeft: 5 }} />
      </RowLogin>
    </AuthCard>
  );
}
