import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/dashboard-layout/button/Button";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./tableConfig";
import { fetchPartnerBranches } from "../../../services/partners.service";
import { useHistory } from "react-router-dom";
import usePagination from "../../../hooks/usePagination";

const Branchs = () => {
  const [branches, setBranchs] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();
  const [searchBarActiveVal, setSearchBarActiveVal] = useState("");
  const history = useHistory();
  const onChangeSearchBar = (e) => {
    setSearchBarActiveVal(e.target.value);
  };
  const onGetBranches = async (pag, filterVal) => {
    setLoading(true);
    try {
      const { data } = await fetchPartnerBranches(
        page,
        itemsPerPage,
        filterVal || ""
      );
      const formatedRows = data?.data?.map((row) => {
        const formatData = {
          id: row.id,
          name: row.name,
          email: row.email || "-",
          phone: row.phoneNumber || "-",
          address: row.address || "-",
          partnerId: row.PartnerId,
        };
        return formatData;
      });
      setBranchs(formatedRows);
      setPagination({
        ...pagination,
        page: data.pagination.currentPage,
        total: data.pagination.total,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRowClick = (e, r) => {
    e.stopPropagation();
    history.push(`/ally/${r.partnerId}/branches/${r.id}`);
  };

  useEffect(() => {
    let timeout = setTimeout(
      () => onGetBranches(pagination, searchBarActiveVal),
      400
    );

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarActiveVal, page, itemsPerPage]);

  return (
    <>
      <DashboardSectionTitle>Sucursales</DashboardSectionTitle>

      <DashboardTable
        columns={columns}
        tableTitle="Lista de sucursales"
        data={branches}
        pagination={pagination}
        page={page}
        rowKey="key"
        loading={loading}
        onClickRow={handleRowClick}
        searchBarPlaceholder="Buscar sucursales"
        onChangeSearchBar={onChangeSearchBar}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        customButton={
          <Button type="primary">
            <Link to={"/branches/create"}>{"+ Crear sucursal"}</Link>
          </Button>
        }
      />
    </>
  );
};

export default Branchs;
