import React from "react";
import styled from "styled-components";
import { Row } from "antd";
import { Text } from "../../containers/dashboard-sa/Clients/NewClient";
import moment from "moment";
import { Link } from "react-router-dom";

const InvoicesItem = ({
  statusColor,
  statusName,
  value,
  initDate,
  id,
  linkTo,
}) => (
  <Link to={linkTo ? `/invoice/${id}?Org` : `/invoice/${id}`}>
    <InvoiceWrapper>
      <Row justify="space-between">
        <Text color="#0A181D" style={{ margin: 0 }}>
          Factura #{id}
        </Text>
        <Text color="#0A181D" style={{ fontWeight: 700, margin: 0 }}>
          ${new Intl.NumberFormat("de-DE").format(value)}
        </Text>
      </Row>
      <Row justify="space-between">
        <StatusItem status={statusColor}>{statusName}</StatusItem>
        <Text size={12} color="#76889A">
          {moment(initDate).format("MMMM DD")}
        </Text>
      </Row>
    </InvoiceWrapper>
  </Link>
);

const renderStatus = (status) => {
  switch (status) {
    case "warning": {
      return `
        background: #F84B051A; 
        color: #F84B05;
      `;
    }
    case "success": {
      return `
      background: #00BE6D1A; 
        color: #00BE6D;
        `;
    }
    case "error": {
      return `
        
        background: #E4032B1A; 
          color: #E4032B;
      
      `;
    }
    default: {
      return ``;
    }
  }
};

const InvoiceWrapper = styled.div`
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #76889a29;
  margin-bottom: 15px;
`;

export const StatusItem = styled.div`
  height: 30px;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${(props) => renderStatus(props.status)}
`;

export default InvoicesItem;
