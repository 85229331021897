import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Breadcrumb } from "antd";
import { BreadcrumItem } from "./styled-components";
import { MdAttachMoney } from "react-icons/md";

import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsPayments } from "./tableConfig";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import Button from "../../../components/dashboard-layout/button/Button";
import AddPaymentForm from "./AddPaymentForm";
import {
  fetchExternalEntities,
  fetchPaymentExternalEntities,
} from "../../../services/external.service";
import { fetchWallet } from "../../../services/wallets.service";

export default function EfectyPayments() {
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filter, setFilter] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [formSuccess, setFormSuccess] = useState(false);
  const history = useHistory();
  const modal = useDashboardModal();
  const onChangePage = (page) => {
    setPagination((pagination) => ({ ...pagination, page }));
  };

  const onChangeFilter = (e) => {
    setFilter(e.target.value);
  };

  const onGetWalletBalance = async () => {
    setLoadingBalance(true);
    try {
      const responseEntities = await fetchExternalEntities(1, 10);
      const efectyType = responseEntities.data.data.filter(
        (elm) => elm.type === "EFECTY"
      )[0];
      const response = await fetchWallet({
        ownerId: efectyType?.id || 1,
        ownerType: "EXTERNAL_ENTITYS",
      });
      setWalletBalance(response?.data?.result?.availableBalance);
      setLoadingBalance(false);
    } catch (error) {
      setLoadingBalance(false);
    }
  };

  const onGetEfectyTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await fetchPaymentExternalEntities(
        pagination.page,
        pagination.pageSize,
        1
      );
      await fetchWallet({
        ownerId: 1,
        ownerType: "EXTERNAL_ENTITYS",
      });
      setTransactions(data.data);
      setPagination((pagination) => ({
        ...pagination,
        page: data.pagination.currentPage,
        total: data.pagination.totalItems,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetWalletBalance();
  }, [formSuccess]);

  useEffect(() => {
    const timeout = setTimeout(onGetEfectyTransactions, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize, filter, formSuccess]);
  return (
    <>
      <Row>
        <Col span={8}>
          <DashboardSectionTitle>Pagos a Efecty</DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/efecty">
              Efecty
            </BreadcrumItem>
            <BreadcrumItem>Pagos</BreadcrumItem>
          </Breadcrumb>
        </Col>
        <Col span={16}>
          <Row justify="end">
            <Col span={10}>
              <DashboardItem
                title="Saldo restante en efecty"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  walletBalance
                )}`}
                factor=""
                icon={<MdAttachMoney />}
                iconColor="#1CA892"
                iconBg="#1CA89212"
                factorStatus="up"
                loading={loadingBalance}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <DashboardTable
        columns={columnsPayments}
        data={transactions}
        pagination={pagination}
        page={pagination.page}
        loading={loading}
        onChangePage={onChangePage}
        tableTitle="Lista de pagos"
        searchBarPlaceholder="Buscar pago"
        onChangeSearchBar={onChangeFilter}
        onClickRow={(text, record) => {
          history.push(`/efecty/${record.id}`);
        }}
        customButton={
          <Button type="primary" actionButton={modal.showModal}>
            + Agregar pago
          </Button>
        }
      />
      <AddPaymentForm
        modalSetting={{ ...modal, title: "Agregar un pago" }}
        setFormSuccess={setFormSuccess}
        formSuccess={formSuccess}
      />
    </>
  );
}
