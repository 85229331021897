import { axiosInstance } from "../utils/axios";

export const fetchDrivers = async (page, itemsPerPage, externalDrivers) => {
  return axiosInstance.get("/drivers", {
    params: {
      page,
      itemsPerPage,
      externalDrivers,
    },
  });
};

export const fetchDriversSearch = async (search) => {
  return axiosInstance
    .get("/drivers/search", {
      params: { text: search },
    })
    .then((res) => res.data);
};

export const fetchDriverInfo = async (driverId) => {
  return axiosInstance
    .get("/drivers/info", {
      params: {
        driverId,
      },
    })
    .then((res) => res.data);
};

export const fetchDriverInvitations = async (page, itemsPerPage) => {
  return axiosInstance.get("/drivers/invitations", {
    params: {
      page,
      itemsPerPage,
    },
  });
};

export const driverCheck = async (data) => {
  return axiosInstance.post("/drivers/check", data).then((res) => res.data);
};
