import { axiosInstance } from "../utils/axios";

export const fetchServiceOrder = async (serviceOrderId) => {
  return axiosInstance
    .get("/services/order", {
      params: {
        serviceOrderId,
      },
    })
    .then((res) => ({ data: res.data.result }));
};

export const updateServiceOrder = async (id, data) => {
  return axiosInstance
    .put(`/services/order/${id}`, data)
    .then((res) => ({ data: res.data.result }));
};

export const fetchServiceOrderLog = async ({
  page,
  itemsPerPage,
  serviceOrderId,
}) => {
  return axiosInstance
    .get("/services/order/log", {
      params: {
        page,
        itemsPerPage,
        serviceOrderId,
      },
    })
    .then((res) => ({ data: res.data.result.data }));
};

export const fetchServiceOrders = async (data) => {
  return axiosInstance
    .get("/services/orders", {
      params: data,
    })
    .then((res) => ({ data: res.data.result }));
};

export const getServiceOrdersCount = async (from, to) => {
  return axiosInstance
    .get("/services/orders/count", {
      params: { from, to },
    })
    .then((res) => ({ data: res.data.result }));
};

export const createServiceOrder = async (data) => {
  return axiosInstance.post("/services/order", data);
};

export const createPaymentServiceOrder = async (data) => {
  return axiosInstance
    .post("/services/payment", data)
    .then((res) => ({ data: res.data.result }));
};
