import styled from "styled-components";
import { Col } from "antd";
import { screen } from "../../../utils/constants";
export const TableContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000012;
  margin-top: 24px;
  flex-direction: column;

  & .row-transparent {
    opacity: 0.5;
  }

  & .ant-table-column-sorters span {
    color: #0a181d;
    font-weight: 500;
  }
  & .ant-table-row .ant-table-row-level-0 {
    cursor: pointer !important;
  }
  & .ant-tag {
    border: none;
    padding: 0.3em 1em;
  }

  & .ant-tag.ant-tag-green {
    color: #00be6d;
    background-color: #00be6d17;
    border: none;
  }

  & th.ant-table-cell {
    color: #0a181d;
    font-weight: bold;
  }

  & svg {
    cursor: pointer;
  }
`;

export const TableToolbar = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & a {
    color: white;
  }
  @media (max-width: ${screen.XS}) {
    flex-direction: column;
    & button {
      margin-top: 5px;
    }
  }
`;
