import styled from "styled-components";
import { Menu, Input, Select, Modal } from "antd";
const { SubMenu } = Menu;

export const FlexContainerHelper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "center"};
`;

export const DashboardWrapper = styled.section`
  background-color: #f3f6fb;
  height: 100vh;
  display: flex;
`;

export const DashboardNavLayout = styled.nav`
  height: 100vh;
  background-color: #051a55;
  flex-direction: column;
  width: 300px;
  align-items: center;
  position: ${(props) => (props.responsiveNav ? "fixed" : "static")};
  display: ${(props) => (props.showNav ? "flex" : "none")};
  z-index: 2;
`;

export const MenuDashboard = styled(Menu)`
  background-color: #051a55;
  color: #ffffffd8;
  border-right: none;
  & li:focus,
  li:hover {
    background: #ffffff10 0% 0% no-repeat padding-box;
    color: #ffffffd8;
  }
  & li:after {
    display: none;
  }
  & .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    background: #ffffff10 0% 0% no-repeat padding-box;
    color: #ffffffd8;
  }
  & a,
  a:hover {
    color: #ffffffd8;
  }
  & svg {
    font-size: 18px;
    margin-right: 10px;
  }
  & span {
    display: flex;
    align-items: center;
  }
`;

export const SubmenuDash = styled(SubMenu)`
  background-color: #051a55;
  color: #ffffffd8;
  border-right: none;
  & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background-color: #fff;
  }
  & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background-color: #fff;
  }
  & .ant-menu-submenu-arrow:before {
    background-color: #fff;
    background-image: unset !important;
  }
  & .ant-menu-submenu-arrow:after {
    background-color: #fff;
    background-image: unset !important;
  }
  & .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
  & .ant-menu-submenu-title:hover {
    color: #fff;
  }
  & .ant-menu-sub.ant-menu-inline {
    background-color: #051a55;
  }
  & li:focus,
  li:hover {
    background: #ffffff10 0% 0% no-repeat padding-box;
    color: #ffffffd8;
  }
  & li:after {
    display: none;
  }
  & .ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-open {
    background: #ffffff10 0% 0% no-repeat padding-box;
    color: #ffffffd8;
  }
  & a,
  a:hover {
    color: #ffffffd8;
  }
  & svg {
    font-size: 18px;
    margin-right: 10px;
  }
  & span {
    display: flex;
    align-items: center;
  }
`;
export const MenuItemGroup = styled(Menu.Item)`
  background-color: #051a55;
  & a {
    color: white;
  }
`;

export const DasboardSection = styled.section`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DashboardMain = styled.main`
  overflow-x: hidden;
  display: flex;
  padding: 20px 30px;
  height: 100%;
  flex-direction: column;
  width: 100%;
`;

export const HeaderLayout = styled.header`
  height: 60px;
  background-color: white;
  padding: 2px 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #76889a;
  & svg {
    cursor: pointer;
  }
`;

export const DashboardSectionTitle = styled.h2`
  color: #0a181d;
  font-size: 24px;
  font-weight: bold;
  margin-top: 1em;
  position: relative;
`;

export const DashboardCardTitle = styled.h4`
  color: #585858;
  font-weight: bolder;
  margin-right: 5px;
  word-wrap: break-word;
`;

export const Indicator = styled.span`
  color: ${(props) => props.color || "#F84B05"};
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 12px;

  & svg {
    font-weight: bold;
  }
`;

export const IconCardWrapper = styled.span`
  color: ${(props) => props.color || "#00BE6D"};
  background-color: ${(props) => props.background || "#00BE6D"};
  padding: 5px;
  font-size: 24px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 34px;
`;

export const DashboardCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  min-height: ${(props) => (props.minheight ? props.minheight : "none")};
  box-shadow: 0px 3px 6px #00000012;
  margin-top: 1rem;
  flex-direction: ${(props) => props.direction || "row"};
  & section {
    flex-grow: 2;
  }
  & aside {
    flex-grow: 1;
    margin-left: auto;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  & span {
    font-size: 12px;
    white-space: nowrap;
  }

  & h2 {
    color: #0a181d;
    font-weight: bold;
  }
  & label {
    color: #0a181d;
    margin-bottom: 0.8rem;
    font-weight: 500;
  }

  & .ant-tag.ant-tag-green {
    color: #00be6d;
    background-color: #00be6d17;
    border: none;
  }
`;

export const DashboardCardText = styled.p`
  color: #585858;
`;

export const Text = styled.span`
  color: ${(props) => props.color || "#585858"};
  display: ${(props) => props.display || "inline"};
  text-align: ${(props) => props.alignment || "left"};
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  justify-content: ${(props) => props.alignment || "flex-start"};
  & svg {
    font-weight: bold;
    margin-right: 3px;
  }
  & a {
    color: ${(props) => props.color || "#585858"};
    text-decoration: none;
    outline: 0;
    cursor: pointer;
  }
`;

export const InputLayout = styled(Input)`
  border: 1px solid #76889a81;
  background-color: #76889a08;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none !important;
  min-height: 40px;
  border-right-width: none !important;
  -webkit-box-shadow: none !important;
  & input {
    background-color: #f5f6f8;
  }

  :read-only {
    border: none !important;
    background-color: #f5f6f8;
  }
`;

export const SelectLayout = styled(Select)`
  border: 1px solid #76889a81;
  background-color: #76889a08;
  border-radius: 5px;
  box-shadow: none !important;
  border-right-width: none !important;
  -webkit-box-shadow: none !important;
  display: block;
  & .ant-select-selector {
    background-color: #76889a08 !important;
    font-size: 16px;
  }
`;

export const ModalLayout = styled(Modal)`
  & .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #050505;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0 !important;
  }

  & .ant-modal-close {
    top: 10px;
    right: 10px;
  }

  & .ant-modal-footer {
    border-top: none;
    display: flex;
    justify-content: flex-end;
  }

  & .ant-modal-content {
    border-radius: 8px;
    padding: 25px;

    & label {
      margin-top: 1em;
    }
    & input {
      background-color: #76889a0d;
    }
  }
`;

export const HistoryContainer = styled.div`
  padding-top: 20px;
  border-top: 1px solid #e3e3e3;
  margin-top: 20px;
  position: relative;
`;

export const HistoryItem = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  & input:focus + ul {
    display: block;
  }
`;

export const SearchOptions = styled.ul`
  display: none;
  width: 100%;
  z-index: 2;
  /* min-height: 50px; */
  max-height: 270px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  background: #fff;
  overflow-y: auto;
  position: absolute;
  top: 65px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  left: 0%;
  :hover {
    ${(props) => (props.focus ? "display: block" : "display: none")}
  }
`;

export const SearchOption = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: ease all 0.3s;
  :hover {
    background: #f2f2f2;
  }
`;

export const SearchedSelect = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 0 10px;
  border: 1px solid ${(props) => (props.disabled ? "transparent" : "#76889a81")};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.disabled && "background: #f5f6f8"}
`;
