import { notification } from "antd";
import axios from "axios";
import { TOKEN_KEY, API_URL, TOKEN_TIME_KEY } from "../utils/constants";
import { signOut } from "../services/firebase";
import firebase from "firebase";

/**
 * Axios Instance
 *
 * This axios instance contains the Bearer token and the BASE URL
 * for the API.
 *
 * @returns AxiosInstance
 */
export const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem(TOKEN_KEY);
    const tokenTime = window.localStorage.getItem(TOKEN_TIME_KEY);
    const currentTime = new Date().getTime();
    const seconds = (currentTime - tokenTime) / 1000;
    const minutes = seconds / 60;

    console.log("Token minutes:", minutes.toFixed(2));

    if (minutes >= 60) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((_token) => {
          console.log("New token created!");
          token = _token;
          localStorage.setItem(TOKEN_TIME_KEY, new Date().getTime());
          localStorage.setItem(TOKEN_KEY, token);
        });
    }

    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(TOKEN_KEY);
      signOut();
      window.location = "/login";
    }

    if (error.response.data && error.response.data.message) {
      notification.error({
        message: error.response?.data?.message,
        description: error.response?.data?.error?.details[0]?.message,
      });
    }

    return Promise.reject(error);
  }
);
